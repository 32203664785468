import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoAdd, IoClose,IoPencil, IoTrash } from "react-icons/io5";
import { Button, Tooltip } from "@mui/material";
import { BASE_URL } from "../../Constants";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "../Permission/PermissionDenied";
import SortIcon from "../sorticon";


const TaskComponent = () => {
  const [tasks, setTasks] = useState([]);
  const [title, setTitle] = useState("");
  const [shortForm, setShortForm] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [wup_tid, setWupTid] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editTaskData, setEditTaskData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Filter tasks based on search query
  const filteredTasks = tasks.filter((task) => 
    task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.shortForm.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.category.toLowerCase().includes(searchQuery.toLowerCase())

  );

// Sort filtered tasks
const sortedTasks = [...filteredTasks].sort((a, b) => {
  const titleA = a.title || ""; 
  const titleB = b.title || "";
  return sortOrder === "asc"
    ? titleA.localeCompare(titleB)
    : titleB.localeCompare(titleA);
});

  

  // Categories for the task
  const categories = [
    "development",
    "design",
    "documentation",
    "testing",
    "management",
    "meeting",
    "other",
  ];

  // Fetch all tasks
  const fetchAllTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/taskrouter/tasks`);
      setTasks(response.data);
    } catch (err) {
      console.error("Error fetching tasks:", err);
    }
  };

  // Handle form submission for creating a task
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !shortForm || !description || !category) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/taskrouter/tasks`, {
        title,
        shortForm,
        description,
        category,
        wup_tid,
      });

      setSuccess("Task created successfully!");
      setError("");
      // Fetch all tasks again to include the newly created task
      fetchAllTasks();
      // Clear form fields
      setTitle("");
      setShortForm("");
      setDescription("");
      setCategory("");
      setWupTid("");
    } catch (err) {
      setError("Failed to create task. Please try again.");
      setSuccess("");
    }
  };

  useEffect(() => {
    fetchAllTasks();
  }, []);

  const resetForm = () => {
    setTitle("");
    setShortForm("");
    setDescription("");
    setCategory("");
    setWupTid("");
    setShowForm(false);
  };

  const validateForm = (editTaskData) => {
    let errors = {};
    if (!editTaskData.title) {errors.title = "Task title is required.";}
    if (!editTaskData.shortForm) {errors.shortForm = "Short form is required.";}
    if (!editTaskData.description) {errors.description = "Description is required.";}
    if (!editTaskData.category || !["development", "rnd", "design", "documentation", "testing", "management", "meeting", "other"].includes(editTaskData.category)) {errors.category = "Invalid category. Please select a valid task category.";}
    setErrors(errors);
    return Object.keys(errors).length === 0; 
  };

  const handleUpdateTask = async (e) => {
    e.preventDefault();
    if (!validateForm(editTaskData)) return; 
  
    const updatePayload = {
      title: editTaskData.title,
      shortForm: editTaskData.shortForm,
      description: editTaskData.description,
      category: editTaskData.category,
      wup_tid: editTaskData.wup_tid,
      createdAt: new Date()
    };
  
    try {
      setLoading(true);
      await axios.put(
        `${BASE_URL}/api/taskrouter/tasks/UpdateTask/${editTaskData._id}`, 
        updatePayload
      );
      setSuccess("Task updated successfully!"); 
      setIsEditing(false); 
      fetchAllTasks(); 
    } catch (error) {
      console.error("Error updating task:", error.response || error);
      alert("Failed to update task.");
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleDeleteTask = async (taskId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this task?");
    if (!confirmDelete) return;
  
    try {
      setLoading(true);
      await axios.delete(`${BASE_URL}/api/taskrouter/tasks/DeleteTask/${taskId}`); 
      alert("Task deleted successfully.");
      window.location.reload(); 
    } catch (error) {
      console.error("Error deleting task:", error.response || error);
      alert("Failed to delete task.");
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (task) => {
    setEditTaskData({ ...task });
    setIsEditing(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setEditTaskData(null);
    setErrors({});
  };

  
  
  // const permissions = localStorage.getItem("permissions");
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  console.log("Permissions");
  return permissions.includes(PERMISSIONS.TASK_PERMISSIONS.VIEW_TASKS) ? (
    <div className="container mx-auto p-4 text-[#333333]">
      <h1 className="text-3xl font-bold mb-4 font-montserrat">Task Management</h1>

      <div className="mb-4">
      <input
        type="text"
        placeholder="Search tasks..."
        className="w-full p-2 border border-gray-300 rounded"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  
      {/* Task List Table */}
      <div className="grid grid-cols-1 gap-6">
  {tasks.length === 0 ? (
    <p>No tasks available.</p>
  ) : (
    <table className="table-auto w-full border-collapse border border-gray-300 shadow-lg text-sm">
      <thead>
        <tr className="bg-gray-100 text-left">
          <th className="border p-4">
          <div className="flex items-center space-x-2">
            <span>Title</span>
            <SortIcon sortOrder={sortOrder} onClick={handleSort} />
          </div>
          </th>
          <th className="border p-4">Description</th>
          <th className="border p-4">Actions</th>
        </tr>
      </thead>
      <tbody>
        {sortedTasks.map((task, index) => (
          <tr
            key={task._id}
            className={`${
              index % 2 === 0 ? "bg-white" : "bg-gray-50"
            } hover:bg-gray-100 cursor-pointer`}
          >
            <td className="border p-4">{task.title}</td>
            <td className="border p-4">{task.description}</td>
            <td className="p-4 flex space-x-2 justify-center">
              <IoPencil
                className="text-blue-600 hover:text-blue-800 cursor-pointer"
                onClick={() => openEditModal(task)}
              />
              <IoTrash
                className="text-red-600 hover:text-red-800 cursor-pointer"
                onClick={() => handleDeleteTask(task._id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
</div>

    
  
      {permissions.includes(PERMISSIONS.TASK_PERMISSIONS.CREATE_TASK) && (
        <button onClick={() => setShowForm(true)}>
          <IoAdd className="text-xl font-bold bg-[#FF480F] text-white rounded-xl p-1 w-[3rem] h-[3rem] fixed right-20 bottom-20" />
        </button>
      )}

      {/* Create Task Form */}
      {showForm && (
        <div className="bg-gray-800/80 fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center">
          <div className="bg-white md:w-1/2  shadow-md p-10 rounded-xl">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2 font-montserrat">Create New Task</h2>
              <IoClose className="text-2xl cursor-pointer" onClick={resetForm} />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block font-medium mb-2 font-montserrat">Title</label>
                  <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="w-full p-2 border rounded" />
                </div>

                <div>
                  <label className="block font-medium mb-2 font-montserrat">Short Form</label>
                  <input type="text" value={shortForm} onChange={(e) => setShortForm(e.target.value)} className="w-full p-2 border rounded" />
                </div>

                <div className="col-span-2">
                  <label className="block font-medium mb-2 font-montserrat">Description</label>
                  <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="w-full p-2 border rounded" rows={4} />
                </div>

                <div>
                  <label className="block font-medium mb-2 font-montserrat">Category</label>
                  <select value={category} onChange={(e) => setCategory(e.target.value)} className="w-full p-2 border rounded">
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                      <option key={cat} value={cat}>
                        {cat.charAt(0).toUpperCase() + cat.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block font-medium mb-2 font-montserrat">Wup TaskId</label>
                  <input type="text" value={wup_tid} onChange={(e) => setWupTid(e.target.value)} className="w-full p-2 border rounded" />
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button type="submit" className="bg-[#FF480F] text-white px-4 py-2 rounded">Create Task</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Task Modal */}
      {isEditing && editTaskData && (
  <div className="bg-gray-800/80 fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center">
    <div className="bg-white w-full shadow-md p-10 rounded-xl">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold mb-2 font-montserrat">Edit Task</h2>
        <IoClose className="text-2xl cursor-pointer" onClick={closeModal} />
      </div>
      {errors.title && <p className="text-red-500">{errors.title}</p>}
      {errors.shortForm && <p className="text-red-500">{errors.shortForm}</p>}
      {errors.description && <p className="text-red-500">{errors.description}</p>}
      {errors.category && <p className="text-red-500">{errors.category}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <form onSubmit={handleUpdateTask}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block font-medium mb-2 font-montserrat">Title</label>
            <input
              type="text"
              value={editTaskData.title}
              onChange={(e) => setEditTaskData({ ...editTaskData, title: e.target.value })}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block font-medium mb-2 font-montserrat">Short Form</label>
            <input
              type="text"
              value={editTaskData.shortForm}
              onChange={(e) => setEditTaskData({ ...editTaskData, shortForm: e.target.value })}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="col-span-2">
            <label className="block font-medium mb-2 font-montserrat">Description</label>
            <textarea
              value={editTaskData.description}
              onChange={(e) => setEditTaskData({ ...editTaskData, description: e.target.value })}
              className="w-full p-2 border rounded"
              rows={4}
            />
          </div>
          <div>
            <label className="block font-medium mb-2 font-montserrat">Category</label>
            <select
              value={editTaskData.category}
              onChange={(e) => setEditTaskData({ ...editTaskData, category: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat.charAt(0).toUpperCase() + cat.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block font-medium mb-2 font-montserrat">Wup TaskId</label>
            <input
              type="text"
              value={editTaskData.wup_tid}
              onChange={(e) => setEditTaskData({ ...editTaskData, wup_tid: e.target.value })}
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button type="button" className="bg-gray-300 px-4 py-2 rounded mr-2" onClick={closeModal}>Cancel</button>
          <button type="submit" className="bg-[#FF480F] text-white px-4 py-2 rounded">Update Task</button>
        </div>
      </form>
    </div>
  </div>
)}

    </div>
  ) : (
    <PermissionDenied />
  );
};


export default TaskComponent;
