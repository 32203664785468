import React, { useContext, useEffect, useState } from "react";
import axios, { HttpStatusCode } from "axios";
import { BASE_URL } from "../../Constants";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "../Permission/PermissionDenied";
import { FaTrash } from "react-icons/fa";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import SavedEntriesTable from "./PreviewTable";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "../UserDashboard";
import ConfirmationModal from "../Modal/ConfirmModal";
import { convertDateToWords } from "../../utensils/dateUtils";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html"; // Import the library for converting Draft.js content to HTML
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
  deleteTimesheetEntry,
  fetchProjects,
  fetchTasks,
  fetchTimesheetsInRange,
} from "../../api/NetworkService";
import {
  addDays,
  getLastSunday,
  isDateLess,
  subtractDays,
} from "../../utils/utils";
const AdminTimesheetForm = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    index: null,
    _id: null,
    wup_nid: null,
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [showEditor, setShowEditor] = useState(false);

  const openModal = (index, _id, wup_nid) => {
    setDeleteParams({ index, _id, wup_nid });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [weekStartOff, setLastWeekStartOff] = useState(getLastSunday());
  const [weekEndOf, setLastWeekEndOf] = useState(addDays(getLastSunday(), 6));
  useEffect(() => {
    const updatedData = {
      ...timesheets[0],
      field_entrydate: new Date().toISOString().split("T")[0],
    };
    setTimesheets([updatedData]);
  }, [weekStartOff]);

  const [timesheets, setTimesheets] = useState([
    {
      title: `wup-${localStorage?.getItem("wup_uid") - Date.now()}`,
      uid: localStorage.getItem("uid"),
      field_entrydate: new Date().toISOString().split("T")[0],
      field_ticket_number: "",
      field_proj: "",
      field_entrytask: "",
      body: "",
      field_time_spent: "",
      wup_pid: "",
      wup_tid: "",
    },
  ]);

  const [savedEntries, setSavedEntries] = useState([]);
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [cloneMode, setCloneMode] = useState(false);
  const [isUnsaved, setHasUnsavedEntry] = useState(false);
  const [tasks, setTasks] = useState({
    recentlyUsed: [],
    commonlyUsed: [],
    others: [],
  });

  const navigate = useNavigate();
  const location = useLocation();
  const previousLocationRef = React.useRef(location);
  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve({ data: { link: event.target.result } });
        };
        reader.readAsDataURL(file);
      }, 1000);
    });
  };
  useEffect(() => {
    const handleWindowClose = (e) => {
      if (isUnsaved) {
        e.preventDefault();
        e.returnValue = ""; // This triggers the browser's "unsaved changes" warning.
      }
    };

    const handleNavigation = (nextLocation) => {
      if (
        isUnsaved &&
        nextLocation.pathname !== previousLocationRef.current.pathname
      ) {
        const confirmLeave = window.confirm(
          "You have unsaved changes. Do you really want to leave?"
        );
        if (confirmLeave) {
          navigate(nextLocation.pathname); // Proceed with navigation
        } else {
          return; // Prevent navigation
        }
      }
    };

    // Add the "beforeunload" event listener for browser refresh, tab close, etc.
    window.addEventListener("beforeunload", handleWindowClose);

    // React Router v6 does not support block, so we manually handle changes
    const unblock = () => {
      const unlisten = location.listen((nextLocation) => {
        handleNavigation(nextLocation);
      });
      return () => {
        unlisten();
      };
    };

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [isUnsaved, navigate, location]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const projectData = await fetchProjects();
        const sortedProjects = projectData.sort((a, b) =>
          a.projectName.localeCompare(b.projectName)
        );
  
        setProjects(sortedProjects);      
      } catch (error) {
        console.error("Error in fetching projects", error);
      }
    };



    const getTasks = async () => {
      try {
        const taskData = await fetchTasks();
        const sortedTasks = {
          recentlyUsed: taskData.recentlyUsed.sort((a, b) => a.title.localeCompare(b.title)),
          commonlyUsed: taskData.commonlyUsed.sort((a, b) => a.title.localeCompare(b.title)),
          others: taskData.others.sort((a, b) => a.title.localeCompare(b.title)),
        };
    
        setTasks(sortedTasks);     
       } catch (error) {
        console.error("Error in fetching tasks", error);
      }
    };

    getProjects();
    getTasks();
  }, []);

  const handleInputChange = (index, e) => {
    setHasUnsavedEntry(true);
    const { name, value } = e.target;
    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index] = { ...updatedTimesheets[index], [name]: value };
    setTimesheets(updatedTimesheets);
  };

  const handleProjectChange = (index, value) => {
    console.log("Project value", value);
    setHasUnsavedEntry(true);

    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index].field_proj = value;
    setTimesheets(updatedTimesheets);
  };

  const handleTaskChange = (index, value) => {
    setHasUnsavedEntry(true);

    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index].field_entrytask = value;
    setTimesheets(updatedTimesheets);
  };

  const handleRemoveTimesheet = (index) => {
    if (index === 0) return;
    const updatedTimesheets = timesheets.filter((_, i) => i !== index);
    setTimesheets(updatedTimesheets);
  };

  const validateForm = () => {
    const errors = timesheets.map((timesheet) => {
      const error = {};
      if (!timesheet.field_proj) error.field_proj = "Project is required";
      if (!timesheet.field_entrytask)
        error.field_entrytask = "Task is required";
      if (!timesheet.field_entrydate)
        error.field_entrydate = "Entry date is required";
      if (!timesheet.field_ticket_number)
        error.field_ticket_number = "Ticket number is required";
      if (!timesheet.field_time_spent)
        error.field_time_spent = "Time spent is required";
      if (
        !timesheet.body &&
        !draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
        error.body = "Description is required";
      return error;
    });

    setErrors(errors);
    return errors.every((error) => Object.keys(error).length === 0);
  };

  useEffect(() => {
    const fetchTimesheets = async () => {
      setIsTableLoading(true);
      setLoading(true);

      try {
        const timesheets = await fetchTimesheetsInRange(
          localStorage.getItem("uid"),
          weekStartOff,
          weekEndOf
        );
        setSavedEntries(timesheets);
      } catch (error) {
        console.error("Error applying filter:", error);
        if (error.response?.status === 404) {
          setSavedEntries([]);
        }
      } finally {
        setIsTableLoading(false);
        setLoading(false);
      }
    };

    fetchTimesheets();
  }, [weekStartOff, weekEndOf]);

  const handleSaveForPreview = () => {
    if (!validateForm()) {
      setErrorMessage("Please fix the errors before saving for preview.");
      return;
    }

    const newEntry = {
      ...timesheets[0],
      projectId: timesheets[0].field_proj, // Store project ID
      field_proj: projects.find(
        (proj) => proj.projectId === timesheets[0].field_proj
      )?.projectName,
    };

    const newEntries = [...savedEntries];
    if (editIndex !== null) {
      newEntries[editIndex] = newEntry;
      setEditIndex(null);
    } else {
      newEntries.push(newEntry);
    }

    return newEntries;
  };

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editMode || cloneMode) {
      const scrollableContainer = document.getElementById(
        "scrollable-container"
      );
      if (scrollableContainer) {
        scrollableContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [editMode, cloneMode]);

  const handleEdit = (index, _id) => {
    console.log("Index", index);
    setEditMode(true);
    // console.log("Edit Id", _id);
    const entryToEdit = index;
    if (!entryToEdit?.isHtml) {
      setShowEditor(false);
    } else {
      setShowEditor(true);
      const contentBlock = htmlToDraft(entryToEdit?.body);
      console.log("Content Block", contentBlock);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
      // setEditorState(entryToEdit?.body);
    }
    // console.log("Entry to edit", entryToEdit);
    console.log("Projects", entryToEdit.field_proj?._id);
    const project = projects.find(
      (proj) => proj.projectId === entryToEdit.field_proj?._id
    );
    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?._id === timesheets?.[0]?.field_entrytask}
    )

    console.log("Get Projects Id", project);
    // console.log("Prefill Data", {
    //   ...entryToEdit,
    //   field_proj: projectId,
    // });
    setTimesheets([
      {
        ...entryToEdit,
        field_proj: entryToEdit.field_proj?._id,
        wup_pid: taskFiltered?.wup_pid,
        wup_uid: localStorage.getItem("wup_username"),

        // wup_tid: task?.wup_tid,
      },
    ]);

    setEditIndex(index);
  };

  const handleClone = (index, _id) => {
    // console.log("Index", index);

    setCloneMode(true);

    // console.log("Edit Id", _id);
    const entryToEdit = index;
    // console.log("Entry to edit", entryToEdit);
    console.log("Projects", entryToEdit.field_proj?._id);
    const project = projects.find(
      (proj) => proj.projectId === entryToEdit.field_proj?._id
    );
    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?._id === timesheets?.[0]?.field_entrytask}
    )

    console.log("Get Projects Id", project);
    // console.log("Prefill Data", {
    //   ...entryToEdit,
    //   field_proj: projectId,
    // });
    setTimesheets([
      {
        ...entryToEdit,
        field_proj: entryToEdit.field_proj?._id,
        wup_pid: taskFiltered?.wup_pid,
        wup_uid: localStorage.getItem("wup_username"),

        wup_nid: null,
        _id: null,
        flagComment: [],
        isApproved: true,
        isFlagged: false,
        uid: localStorage.getItem("uid"),
        title: `wup-${localStorage?.getItem("wup_uid") - Date.now()}`,

        // wup_tid: task?.wup_tid,
      },
    ]);
    // setEditIndex(index);
  };

  const handleDelete = async () => {
    setLoading(true);
    closeModal();

    const { _id, wup_nid } = deleteParams;
    const updatedEntries = savedEntries.filter((item) => item._id !== _id);

    try {
      const response = await deleteTimesheetEntry(_id, wup_nid); // Use the modular service function

      if (response.status === 200) {
        setSavedEntries(updatedEntries); // Update state on success
        setModalOpen(false); // Close modal on successful deletion
      } else {
        console.error("Failed to delete the timesheet entry");
      }
    } catch (error) {
      alert("There was an issue deleting the entry. Please try again.");
    } finally {
      setLoading(false); // Ensure loading state is stopped
    }
  };

  const handleUpdateSubmit = async (event) => {
    handleSaveForPreview();
    setIsLoading(true);
    event.preventDefault();

    const project = projects.find(
      (proj) => proj._id === timesheets?.[0]?.field_proj
    );
    console.log("ProjectId", project);
    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?.shortForm === timesheets?.[0]?.field_entrytask}
    )

    const data = showEditor
      ? {
          body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          field_entrydate: timesheets?.[0]?.field_entrydate,
          field_entrytask: timesheets?.[0]?.field_entrytask,
          field_proj: timesheets?.[0]?.field_proj,
          field_ticket_number: timesheets?.[0]?.field_ticket_number,
          field_time_spent: timesheets?.[0]?.field_time_spent,
          wup_pid: project?.wup_pid,
          wup_tid: taskFiltered?.wup_tid,
          // title: `wup-${localStorage?.getItem("wup_username")}-${Date.now()}`,
        }
      : {
          body: timesheets?.[0]?.body,
          field_entrydate: timesheets?.[0]?.field_entrydate,
          field_entrytask: timesheets?.[0]?.field_entrytask,
          field_proj: timesheets?.[0]?.field_proj,
          field_ticket_number: timesheets?.[0]?.field_ticket_number,
          field_time_spent: timesheets?.[0]?.field_time_spent,
          wup_pid: project?.wup_pid,
          wup_tid: taskFiltered?.wup_tid,
        };
    axios
      .put(
        `${BASE_URL}/api/timesheet/updateTimesheetEntryById/${
          timesheets?.[0]?._id
        }/${timesheets?.[0]?.wup_nid}/${
          timesheets?.[0]?.title
        }/${localStorage?.getItem("wup_username")}/${data?.wup_pid}/${
          data?.wup_tid
        }?editor=${showEditor}`,
        data
      )
      .then(async (response) => {
        if (response.status === 200) {
          console.log("Updated successfully:", response.data);
          setIsLoading(false);
          setEditMode(false);
          setTimesheets([
            // ...timesheets,
            {
              title: "",
              uid: localStorage.getItem("uid"),
              field_entrydate: new Date().toISOString().split("T")[0],
              field_ticket_number: "",
              field_proj: "",
              field_entrytask: "",
              body: "",
              field_time_spent: "",
            },
          ]);
          setEditorState(EditorState.createEmpty());
          try {
            setLoading(true);

            const data = await fetchTimesheetsInRange(
              localStorage.getItem("uid"),
              weekStartOff,
              weekEndOf
            );
            setLoading(false);
            setSavedEntries(data);
          } catch (error) {
            setLoading(false);
          }
          setHasUnsavedEntry(false);
        }
      })
      .catch((error) => {
        console.error("Error updating timesheet:", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const projectF = projects.find(
      (proj) => proj._id === timesheets?.[0]?.field_proj
    );
    console.log("Data", timesheets);
    if (!validateForm()) {
      setErrorMessage("Please fix the errors before saving for preview.");
      return;
    }
    setErrorMessage("");
    setSuccessMessage("");
    handleSaveForPreview();
    if (timesheets.length === 0) {
      setErrorMessage("Minimum 1 entry is required");
      return;
    }
    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?.shortForm === timesheets?.[0]?.field_entrytask}
    )

    const newFormData = !showEditor
      ? [
          {
            ...timesheets[0],
            wup_uid: localStorage.getItem("wup_username"),
            wup_pid: projectF?.wup_pid,
            wup_tid: taskFiltered?.wup_tid,
            title: `wup-${localStorage?.getItem("wup_uid")}-${Date.now()}`,
          },
        ]
      : [
          {
            ...timesheets[0],
            wup_uid: localStorage.getItem("wup_username"),
            wup_pid: projectF?.wup_pid,
            wup_tid: taskFiltered?.wup_tid,
            title: `wup-${localStorage?.getItem("wup_uid")}-${Date.now()}`,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          },
        ];

    console.log("Entries", newFormData);
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/timesheet/massInsert?editor=${showEditor}`,
        newFormData
      );
      if (response.status === 201) {
        setSuccessMessage("Timesheets submitted successfully!");
        setHasUnsavedEntry(false);
        console.log("Reset Data", projectF, {
          title: "",
          uid: localStorage.getItem("uid"),
          field_entrydate: new Date().toISOString().split("T")[0],
          field_ticket_number: "",
          field_entrytask: "",
          body: "",
          field_time_spent: "",
          field_proj: projectF?._id,
        });
        setTimesheets([
          {
            title: "",
            uid: localStorage.getItem("uid"),
            field_entrydate: new Date().toISOString().split("T")[0],
            field_ticket_number: "",
            field_entrytask: "",
            body: "",
            field_time_spent: "",
            field_proj: projectF?._id,
          },
        ]);

        setEditorState(EditorState.createEmpty());
        const data = await fetchTimesheetsInRange(
          localStorage.getItem("uid"),
          weekStartOff,
          weekEndOf
        );
        setSavedEntries(data);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  if (
    !permissions?.includes(PERMISSIONS.TIMESHEET_PERMISSIONS.SUBMIT_TIMESHEET)
  ) {
    return <PermissionDenied />;
  }

  return (
    <div className="flex flex-wrap justify-center text-[#333333]">
      <div className="w-full p-4 md:p-6 bg-white rounded-lg">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <button
              type="button"
              onClick={() => {
                setLastWeekStartOff(subtractDays(weekStartOff, 7));
                setLastWeekEndOf(subtractDays(weekEndOf, 7));
              }}
              className="text-white bg-[#213251] rounded-full py-2 px-4 flex items-center"
            >
              <IoMdArrowRoundBack className="mr-2"/>
              <span className="block">Previous Week</span>
            </button>
          <h1 className="text-2xl font-semibold my-4 md:w-[25rem] text-center font-montserrat ">
            {isDateLess(weekEndOf, currentDate)
              ? `Timesheet Entry`
              : `Timesheet Entry`}
          </h1>
          {isDateLess(weekEndOf, currentDate) ? (
              <button
                type="button"
                onClick={() => {
                  setLastWeekStartOff(addDays(weekStartOff, 7));
                  setLastWeekEndOf(addDays(weekEndOf, 7));
                }}
                className="text-white bg-[#213251] rounded-full  py-2 px-4 flex items-center"
              >
                
              <span className="block">Next Week</span>
              <IoMdArrowRoundForward className="ml-2"/>  
              </button>
          ) : (
            <div></div>
          )}
        </div>
        <p className="font-medium self-center w-full text-center pb-3 text-blue-500">
          From {convertDateToWords(weekStartOff.toString())} to{" "}
          {convertDateToWords(weekEndOf.toString())}
        </p>
        {successMessage && (
          <p className="text-green-600 mb-2">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-600 mb-2">{errorMessage}</p>}

        <form
          onSubmit={editMode ? handleUpdateSubmit : handleSubmit}
          className="space-y-4"
        >
          {timesheets.map((timesheet, index) => (
            <div
              key={index}
              className="bg-gray-50 p-4 rounded-lg shadow-md mb-4"
            >
              <div className="grid grid-cols-1 md:grid-cols-5 gap-4 items-center">
                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Project Name <span className="text-red-600">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded mt-1 p-2 "
                    value={timesheet.field_proj}
                    onChange={(e) => handleProjectChange(index, e.target.value)}
                  >
                    <option value="">Select Project</option>

                    {projects.map((project) => (
                      <option key={project.project_id} value={project._id}>
                        {project.projectName}
                      </option>
                    ))}
                  </select>
                  {errors[index]?.field_proj && (
                    <p className="text-red-500">{errors[index].field_proj}</p>
                  )}
                </div>

                <div>
      <label className="block text-gray-700 font-montserrat">
        Task Name <span className="text-red-600">*</span>
      </label>
      <select
        className="w-full border border-gray-300 rounded mt-1 p-2"
        value={timesheet.field_entrytask}
        onChange={(e) => handleTaskChange(index, e.target.value)}
      >
        <option value="">Select Task</option>

        {/* Recently Used Tasks */}
        <optgroup label="Recently Used">
          {tasks.recentlyUsed.map((task, i) => (
            <option key={`recent-${i}`} value={task.shortForm}>
              {task.title}
            </option>
          ))}
        </optgroup>

        {/* Commonly Used Tasks */}
        <optgroup label="Commonly Used">
          {tasks.commonlyUsed.map((task, i) => (
            <option key={`common-${i}`} value={task.shortForm}>
              {task.title}
            </option>
          ))}
        </optgroup>

        {/* Other Tasks */}
        <optgroup label="Others">
          {tasks.others.map((task, i) => (
            <option key={`other-${i}`} value={task.shortForm}>
              {task.title}
            </option>
          ))}
        </optgroup>
      </select>

      {errors[index]?.field_entrytask && (
        <p className="text-red-500">
          {errors[index].field_entrytask}
        </p>
      )}
    </div>

                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Entry Date <span className="text-red-600">*</span>
                  </label>

                  <input
                    type="date"
                    name="field_entrydate"
                    value={
                      timesheet.field_entrydate ||
                      new Date().toISOString().split("T")[0]
                    }
                    min={weekStartOff}
                    max={weekEndOf}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.field_entrydate && (
                    <p className="text-red-500">
                      {errors[index].field_entrydate}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Ticket Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="field_ticket_number"
                    value={timesheet.field_ticket_number}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.field_ticket_number && (
                    <p className="text-red-500">
                      {errors[index].field_ticket_number}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Time Spent (hours) <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    name="field_time_spent"
                    value={timesheet.field_time_spent}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.field_time_spent && (
                    <p className="text-red-500">
                      {errors[index].field_time_spent}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-5 my-4">
                <button
                  className={
                    !showEditor
                      ? `rounded-full bg-[#077fc0] px-4 py-1 shadow-xl text-white font-montserrat`
                      : `rounded-full  px-4 py-1  border font-montserrat `
                  }
                  onClick={() => setShowEditor(false)}
                >
                  Text View
                </button>
                <button
                  className={
                    showEditor
                      ? `rounded-full bg-[#077fc0] px-4 py-1 shadow-xl text-white font-montserrat`
                      : `rounded-full  px-4 py-1  border font-montserrat`
                  }
                  onClick={() => setShowEditor(true)}
                >
                  HTML Editor
                </button>
              </div>
              {!showEditor && (
                <div className="mt-4">
                  <label className="block text-gray-700 font-montserrat">
                    Details <span className="text-red-600 ">*</span>
                  </label>
                  <textarea
                    name="body"
                    value={timesheet.body}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.body && (
                    <p className="text-red-500">{errors[index].body}</p>
                  )}
                </div>
              )}

              {showEditor && (
                <div style={{ border: "1px solid #ccc", padding: "8px" }}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    // onEditorStateChange={onEditorStateChange}
                    onEditorStateChange={setEditorState}
                    mention={{
                      separator: " ",
                      trigger: "@",
                    }}
                    toolbar={{
                      image: {
                        uploadCallback: uploadImageCallBack,
                        alt: { present: true, mandatory: false },
                      },
                    }}
                  />
                </div>
              )}

              {index > 0 && (
                <button
                  type="button"
                  onClick={() => handleRemoveTimesheet(index)}
                  className="bg-red-500 text-white py-1 px-4 mt-2 flex gap-2 items-center rounded"
                >
                  <FaTrash /> Remove
                </button>
              )}
            </div>
          ))}

          <div className="flex justify-between mt-4">
            {/* <button
              type="button"
              onClick={handleSaveForPreview}
              className="bg-green-500 text-white py-2 px-4 rounded"
            >
              Save Timesheet
            </button> */}

            <div className="flex justify-end mt-4">
              <button
                type="submit"
                onClick={editMode ? handleUpdateSubmit : handleSubmit}
                className={`bg-[#077fc0] text-white py-2 px-4 rounded font-montserrat ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <ProgressLoader /> Submitting...
                  </>
                ) : editMode ? (
                  "Update Timesheet"
                ) : (
                  "Submit Timesheets"
                )}
              </button>
            </div>
          </div>
        </form>
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={handleDelete}
        />
        {isTableLoading ? (
          "Fetching Records..."
        ) : (
          <SavedEntriesTable
            handleEdit={handleEdit}
            savedEntries={savedEntries}
            handleDelete={openModal}
            handleClone={handleClone}
          />
        )}
      </div>
    </div>
  );
};

export default AdminTimesheetForm;
