import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { Divider } from "@mui/material";
import { fetchUserData } from "../../api/NetworkService";

const AdminHome = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const userId = localStorage?.getItem("uid"); // Your user ID

  useEffect(() => {
    fetchUserData(userId)
      .then((userData) => setUserData(userData))
      .catch((e) => {
        console.log("Error", e);
        setError("Failed to fetch user data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId]);

  if (isLoading) {
    return <ProgressLoader />;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  if (!userData) {
    return <p className="text-gray-600">No user data found.</p>;
  }

  return (
    <div className="container mx-auto p-2 text-[#333333]">
      <div className="bg-white rounded-lg p-6">
        <h2 className="text-3xl font-bold mb-4 font-montserrat ">
          User Profile
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 font-montserrat">
              Full Name
            </h3>
            <p className="text-gray-600">{`${userData.fname} ${userData.lname}`}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 font-montserrat">
              Email
            </h3>
            <p className="text-gray-600 break-words">{userData.email}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 font-montserrat">
              Employee ID
            </h3>
            <p className="text-gray-600">{userData.employeeId}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 font-montserrat">
              Employment Status
            </h3>
            <p className="text-gray-600">{userData.employmentStatus}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 font-montserrat">
              Active Status
            </h3>
            <p
              className={`text-gray-600 ${
                userData.isActive ? "text-green-600" : "text-red-600"
              }`}
            >
              {userData.isActive ? "Active" : "Inactive"}
            </p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 font-montserrat">
              Active Since
            </h3>
            <p className="text-gray-600 ">
              {new Date(userData.createdAt).toLocaleString()}
            </p>
          </div>
        </div>

        <Divider />
        {/* User Roles */}
        <div className="bg-white  rounded-lg p-4 mb-6 ">
          <h3 className="text-xl font-bold mb-4 font-montserrat">Roles</h3>
          <ul className="list-disc list-inside">
            {userData?.roles?.map((role, index) => (
              <li key={index} className="text-gray-600">
                {role}
              </li>
            ))}
          </ul>
        </div>
        <Divider />

        {/* User Projects */}
        <div className="bg-white   rounded-lg p-4">
          <h3 className="text-xl font-bold mb-4 font-montserrat">Projects</h3>
          <ul className="space-y-4">
            {userData?.projects?.map((project) => (
              <li
                key={project._id}
                className="bg-gray-50 p-4 rounded-lg shadow"
              >
                <h4 className="text-lg font-bold text-gray-700 font-montserrat">
                  {project.projectId.projectName}
                </h4>
                <p className="text-gray-600">
                  <strong className="font-montserrat">Role:</strong>{" "}
                  {project.role}
                </p>
                <p className="text-gray-600 capitalize">
                  <strong className="font-montserrat">Project Type:</strong>{" "}
                  {project.projectId.type}
                </p>
                <p className="text-gray-600">
                  <strong className="font-montserrat">Status:</strong>{" "}
                  <span
                    className={`font-semibold capitalize ${
                      project.projectId.status === "active"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {project.projectId.status}
                  </span>
                </p>
                <p className="text-gray-600">
                  <strong className="font-montserrat">Start Date:</strong>{" "}
                  {new Date(project.projectId.startDate).toLocaleDateString()}
                </p>
                {project.projectId.endDate && (
                  <p className="text-gray-600">
                    <strong>End Date:</strong>{" "}
                    {new Date(project.projectId.endDate).toLocaleDateString()}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
