import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoFlag, IoFlagOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../Constants";
import { PERMISSIONS } from "./PermissionsConstant";
import PermissionDenied from "./Permission/PermissionDenied";
import { ReviewTimesheetTable } from "./TimesheetSection/ReviewTimesheetTable";
import ProgressLoader from "./ProgressLoader/ProgressLoader";
import { useLocation } from "react-router-dom";

const TimesheetPage = () => {
  const location = useLocation(); // Access the current location
  const queryParams = new URLSearchParams(location.search);
  const [timesheets, setTimesheets] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [filterProject, setFilterProject] = useState("");
  const [filterTask, setFilterTasks] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reason, setReason] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [showFlagForm, setShowFlagForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Calculate default dates
    const today = new Date();
    const weekAgo = new Date();
    weekAgo.setDate(today.getDate() - 7);

    setEndDate(today.toISOString().split("T")[0]);
    setStartDate(weekAgo.toISOString().split("T")[0]);

    // Fetch users, projects, and tasks
    fetchUsers();
    fetchProjects();
    fetchTasks();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error); // Using toast.error for better styling
      setError("");
    }
  }, [error]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/getUsers`);
      const sortedUsers = response.data.sort((a, b) => {
        const nameA = `${a.fname} ${a.lname}`.toLowerCase();
        const nameB = `${b.fname} ${b.lname}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setUsers(sortedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/project/projects`);
      const sortedProjects = response.data.sort((a, b) => 
        a.projectName.localeCompare(b.projectName)
      );
      setProjects(sortedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  
  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/taskrouter/tasks`);
      const sortedTasks = response.data.sort((a, b) => 
        a.title.localeCompare(b.title)
      );
      setTasks(sortedTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  

  const flagTimesheet = async (_id, status, comment) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/timesheet/flagTimeSheet`,
        { _id, status, comment }
      );
      setLoading(false);

      if (response.status === 200) {
        setShowFlagForm(false);
        handleFilter();
      }
    } catch (error) {
      setLoading(false);
      alert("Something went wrong");
      console.error("Error flagging timesheet:", error);
    }
  };

  const handleFilter = async () => {
    setError("");
    if (!startDate || !endDate) {
      setError("Start date and end date are required.");
      return;
    }
  
    if (new Date(startDate) > new Date(endDate)) {
      setError("Start date cannot be later than end date.");
      return;
    }
  
    setLoading(true);
  
    try {
      const params = {
        userId: filterUser || undefined,  
        startDate,
        endDate,
        projectId: filterProject || undefined,
        task: filterTask || undefined,
      };
  
      const response = await axios.get(`${BASE_URL}/api/timesheet/getTimeSheets`, { params });
      setTimesheets(response.data);
    } catch (error) {
      console.error("Error applying filter:", error);
      if (error.response?.status === 404) {
        setTimesheets([]);
      }
      setError(error?.response?.data?.error || "Failed to apply filter. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchCustomFilter();
  }, []);
  function convertDateFormat(dateStr) {
    const [day, month, year] = dateStr.split("/");
    return `${year}-${month}-${day}`;
  }

  const fetchCustomFilter = async () => {
    const startDate = queryParams.get("startDate");
    const endDate = queryParams.get("endDate");
    const projectId = queryParams.get("projectId"); 
    const userId = queryParams.get("userId");

    if (!startDate || !endDate || !userId) {
      return;
    }
    setStartDate(convertDateFormat(startDate));
    setEndDate(convertDateFormat(endDate));
    // setFilterProject(projectId);
    setFilterUser(userId);
    if (startDate && endDate && projectId && userId) {
      const params = {
        userId: userId || undefined,
        // projectId: projectId || undefined,
        task: filterTask || undefined,
        startDate: convertDateFormat(startDate) || undefined,
        endDate: convertDateFormat(endDate) || undefined,
      };

      console.log("Filter Params", params);
      Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key]
      );

      // setIsLoading(true);

      try {
        const response = await axios.get(
          `${BASE_URL}/api/timesheet/getTimeSheets`,
          { params }
        );
        setTimesheets(response.data);
        setError("");
      } catch (error) {
        console.error("Logged error", error.response.data.error);
        setError(
          error?.response?.data?.error ||
            "Failed to fetch timesheets. Please try again."
        );
        setTimesheets([]);
      } finally {
        // setIsLoading(false);
      }
    }
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  return permissions.includes(
    PERMISSIONS.DATA_FILTERING_REPORTING_PERMISSIONS.VIEW_DATA_FILTERS
  ) ? (
    <div className="container mx-auto p-4 text-[#333333]">
      <h1 className="text-3xl font-bold mb-4 font-montserrat">
        Admin Timesheet Management
      </h1>

      {/* Filter Section */}
      <div className="bg-white p-4 mb-6 rounded">
        <h2 className="text-xl font-semibold mb-2">Filter Timesheets</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block font-medium mb-2">
              Filter by User<sup className="text-red-600">*</sup>
            </label>
            <select
              value={filterUser}
              onChange={(e) => setFilterUser(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name} {user.fname} {user.lname}
                </option>
              ))}
            </select>
          </div>

          {permissions.includes(
            PERMISSIONS.PROJECT_PERMISSIONS.VIEW_PROJECTS
          ) && (
            <div>
              <label className="block font-medium mb-2">
                Filter by Project
              </label>
              <select
                value={filterProject}
                onChange={(e) => setFilterProject(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Project</option>
                {projects.map((project) => (
                  <option key={project._id} value={project._id}>
                    {project && project?.projectName}
                  </option>
                ))}
              </select>
            </div>
          )}

          {permissions.includes(
            PERMISSIONS.PROJECT_PERMISSIONS.VIEW_PROJECT_TASKS
          ) && (
            <div>
              <label className="block font-medium mb-2">Filter by Task</label>
              <select
                value={filterTask}
                onChange={(e) => setFilterTasks(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">All Tasks</option>
                {tasks.map((task) => (
                  <option key={task._id} value={task.shortForm}>
                    {task.title}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label className="block font-medium mb-2">
              Start Date <sup className="text-red-600">*</sup>
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label className="block font-medium mb-2">
              End Date <sup className="text-red-600">*</sup>
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="col-span-1 md:col-span-3 flex justify-end">
            <button
              onClick={handleFilter}
              className="bg-[#077FC0] text-white px-4 py-2 rounded mt-4"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>

      {loading && <ProgressLoader />}

      <ReviewTimesheetTable
        timesheets={timesheets}
        setShowFlagForm={setShowFlagForm}
        setCurrentId={setCurrentId}
        start_date={startDate}
        end_date={endDate}
        project={filterProject}
        username={
          users?.find((user) => user?._id === filterUser)?.fname || "Unknown"
        }
      />

      {showFlagForm && (
        <div className="fixed w-full h-screen bg-gray-800/80 left-0 right-0 top-0 flex justify-center items-center shadow-inner bottom-0">
          <div className="bg-white w-1/2 rounded-xl p-8 flex gap-2 flex-col">
            <input
              type="text"
              className="p-4 border"
              placeholder="Enter reason for flag"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
            <div className="flex gap-4">
              <button
                className="px-3 py-2 bg-[#077FC0] text-white rounded-md"
                onClick={() => flagTimesheet(currentId, true, reason)}
              >
                Submit
              </button>
              <button
                className="px-3 py-2 bg-red-500 text-white rounded-md"
                onClick={() => setShowFlagForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  ) : (
    <PermissionDenied message="To access data filter get access from admin." />
  );
};

export default TimesheetPage;
