import React, { useContext, useEffect, useState } from "react";
import axios, { HttpStatusCode } from "axios";
import { BASE_URL } from "../../Constants";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "../Permission/PermissionDenied";
import { FaTrash } from "react-icons/fa";
import { SlPencil } from "react-icons/sl";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import SavedEntriesTable from "./PreviewTable";
import { useLocation, useNavigate } from "react-router-dom";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { Tooltip } from "../UserDashboard";
import ConfirmationModal from "../Modal/ConfirmModal";
import { scrollToTop, trackEmployementStatus } from "../../utils/utils";
import { convertDateToWords } from "../../utensils/dateUtils";
import { ShowTimesheetApprovalPopup } from "./ApprovalPopup";
const TimesheetForm = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    index: null,
    _id: null,
    wup_nid: null,
  });
  const openModal = (index, _id, wup_nid) => {
    console.log("Hello world");
    setDeleteParams({ index, _id, wup_nid });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const subtractDays = (date, days) => {
    const resultDate = new Date(date); // Create a new date object to avoid mutating the original date
    resultDate.setDate(resultDate.getDate() - days); // Subtract the specified number of days
    return resultDate.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
  };
  const isDateInRange = (date) => {
    const givenDate = new Date(date); // Convert input to a Date object
    const startDate = new Date(weekStartOff); // Convert input to a Date object
    const endDate = new Date(weekEndOf); // Convert input to a Date object
    return givenDate >= startDate && givenDate <= endDate; // Returns true if given date is before the current date
  };
  const isDateLess = () => {
    const endDate = new Date(weekEndOf); // Convert input to a Date object
    return endDate < new Date(currentDate); // Returns true if given date is before the current date
  };

  const isDateGreater = () => {
    const startDate = new Date(weekStartOff); // Convert input to a Date object
    return startDate > new Date(currentDate); // Returns true if given date is before the current date
  };
  const addDays = (date, days) => {
    const resultDate = new Date(date); // Create a new date object to avoid mutating the original date
    resultDate.setDate(resultDate.getDate() + days); // Subtract the specified number of days
    return resultDate.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
  };
  const getLastDayOfWeek = (date) => {
    const resultDate = new Date(date); // Create a new date object
    const day = resultDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const lastDay = new Date(
      resultDate.setDate(resultDate.getDate() + (7 - day))
    ); // Calculate the last day (Sunday)
    return lastDay.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
  };
  const getLastSaturday = () => {
    const today = new Date();
    const lastSaturday = new Date(today);
    // Calculate the last Saturday's date
    lastSaturday.setDate(today.getDate() - ((today.getDay() + 1) % 7) - 1);
    return lastSaturday.toISOString().split("T")[0];
  };
  const getLastSunday = () => {
    const today = new Date();
    const lastSunday = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    return lastSunday.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
  };
  const [weekStartOff, setLastWeekStartOff] = useState(getLastSunday());
  const [weekEndOf, setLastWeekEndOf] = useState(addDays(getLastSunday(), 6));
  useEffect(() => {
    const updatedData = {
      ...timesheets[0],
      field_entrydate: new Date().toISOString().split("T")[0],
    };
    setTimesheets([updatedData]);
  }, [weekStartOff]);

  const [timesheets, setTimesheets] = useState([
    {
      title: "",
      uid: localStorage.getItem("uid"),
      field_entrydate: new Date().toISOString().split("T")[0],
      field_ticket_number: "",
      field_proj: "",
      field_entrytask: "",
      body: "",
      field_time_spent: "",
    },
  ]);

  const [savedEntries, setSavedEntries] = useState([]);
  const [projects, setProjects] = useState([]);
  // const [tasks, setTasks] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [isUnsaved, setHasUnsavedEntry] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const previousLocationRef = React.useRef(location);

 
    const [tasks, setTasks] = useState({
      recentlyUsed: [],
      commonlyUsed: [],
      others: [],
    });

  useEffect(() => {
    const handleWindowClose = (e) => {
      if (isUnsaved) {
        e.preventDefault();
        e.returnValue = ""; 
      }
    };

    const handleNavigation = (nextLocation) => {
      if (
        isUnsaved &&
        nextLocation.pathname !== previousLocationRef.current.pathname
      ) {
        const confirmLeave = window.confirm(
          "You have unsaved changes. Do you really want to leave?"
        );
        if (confirmLeave) {
          navigate(nextLocation.pathname); // Proceed with navigation
        } else {
          return; // Prevent navigation
        }
      }
    };

    // Add the "beforeunload" event listener for browser refresh, tab close, etc.
    window.addEventListener("beforeunload", handleWindowClose);

    // React Router v6 does not support block, so we manually handle changes
    const unblock = () => {
      const unlisten = location.listen((nextLocation) => {
        handleNavigation(nextLocation);
      });
      return () => {
        unlisten();
      };
    };

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [isUnsaved, navigate, location]);

  

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/getProjectByUserId/${localStorage.getItem("uid")}`
      );
      const sortedProjects = response.data?.projects.sort((a, b) => 
        a.projectName.localeCompare(b.projectName)
      );
  
      setProjects(sortedProjects);    
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/getUserTasks/${localStorage.getItem("uid")}`);
      const sortedTasks = {
        recentlyUsed: response.data.recentlyUsed.sort((a, b) => a.title.localeCompare(b.title)),
        commonlyUsed: response.data.commonlyUsed.sort((a, b) => a.title.localeCompare(b.title)),
        others: response.data.others.sort((a, b) => a.title.localeCompare(b.title)),
      };
  
      setTasks(sortedTasks);    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchTasks();
  }, []);

  const handleInputChange = (index, e) => {
    setHasUnsavedEntry(true);
    const { name, value } = e.target;
    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index] = { ...updatedTimesheets[index], [name]: value };
    setTimesheets(updatedTimesheets);
  };

  const handleProjectChange = (index, value) => {
    setHasUnsavedEntry(true);

    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index].field_proj = value;
    setTimesheets(updatedTimesheets);
  };

  const handleTaskChange = (index, value) => {
    setHasUnsavedEntry(true);

    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index].field_entrytask = value;
    setTimesheets(updatedTimesheets);
  };

  const handleClone = (index, _id) => {
    // console.log("Index", index);

    setCloneMode(true);

    // console.log("Edit Id", _id);
    const entryToEdit = index;
    // console.log("Entry to edit", entryToEdit);
    console.log("Projects", entryToEdit.field_proj?._id);
    const project = projects.find(
      (proj) => proj.projectId === entryToEdit.field_proj?._id
    );
    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?._id === timesheets?.[0]?.field_entrytask}
    )
    // const taskFiltered = tasks.find(
    //   (task) => task?._id === timesheets?.[0]?.field_entrytask
    // );

    console.log("Get Projects Id", project);
    // console.log("Prefill Data", {
    //   ...entryToEdit,
    //   field_proj: projectId,
    // });
    setTimesheets([
      {
        ...entryToEdit,
        field_proj: entryToEdit.field_proj?._id,
        wup_pid: taskFiltered?.wup_pid,
        wup_uid: localStorage.getItem("wup_username"),

        wup_nid: null,
        _id: null,
        flagComment: [],
        isApproved: true,
        isFlagged: false,
        uid: localStorage.getItem("uid"),
        title: `wup-${localStorage?.getItem("wup_uid") - Date.now()}`,

        // wup_tid: task?.wup_tid,
      },
    ]);
    // setEditIndex(index);
  };

  // const handleAddTimesheet = () => {
  //   const lastEntry = timesheets[timesheets.length - 1];
  //   setTimesheets([
  //     ...timesheets,
  //     {
  //       title: "",
  //       uid: lastEntry.uid,
  //       field_entrydate: weekStartOff,
  //       field_ticket_number: "",
  //       field_proj: lastEntry.field_proj,
  //       field_entrytask: lastEntry.field_entrytask,
  //       body: "",
  //       field_time_spent: "",
  //     },
  //   ]);
  // };

  const handleRemoveTimesheet = (index) => {
    if (index === 0) return;
    const updatedTimesheets = timesheets.filter((_, i) => i !== index);
    setTimesheets(updatedTimesheets);
  };

  const validateForm = () => {
    const errors = timesheets.map((timesheet) => {
      console.log("Validation", timesheet);
      const error = {};
      if (!timesheet.field_proj) error.field_proj = "Project is required";
      if (!timesheet.field_entrytask)
        error.field_entrytask = "Task is required";
      if (!timesheet.field_entrydate)
        error.field_entrydate = "Entry date is required";
      if (!timesheet.field_ticket_number)
        error.field_ticket_number = "Ticket number is required";
      if (!timesheet.field_time_spent)
        error.field_time_spent = "Time spent is required";
      if (!timesheet.body) error.body = "Description is required";
      return error;
    });

    setErrors(errors);
    return errors.every((error) => Object.keys(error).length === 0);
  };

  useEffect(() => {
    fetchTimesheetsInRange();
  }, [weekEndOf, weekStartOff]);

  const fetchTimesheetsInRange = async () => {
    setIsTableLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/timesheet/getTimeSheets?userId=${localStorage.getItem(
          "uid"
        )}&startDate=${weekStartOff}&endDate=${weekEndOf}`
      );
      if (response.status === 200) {
        setSavedEntries(response.data);
        setIsTableLoading(false);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error applying filter:", error);
      if (error.response.status === 404) {
        setSavedEntries([]);
      }
      setIsTableLoading(false);
      setLoading(false);
    }
  };

  const handleSaveForPreview = () => {
    if (!validateForm()) {
      setErrorMessage("Please fix the errors before saving for preview.");
      return;
    }

    const newEntry = {
      ...timesheets[0],
      projectId: timesheets[0].field_proj, // Store project ID
      field_proj: projects.find(
        (proj) => proj.projectId === timesheets[0].field_proj
      )?.projectName,
    };

    const newEntries = [...savedEntries];
    if (editIndex !== null) {
      newEntries[editIndex] = newEntry;
      setEditIndex(null);
    } else {
      newEntries.push(newEntry);
    }

    return newEntries;
  };

  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [cloneMode, setCloneMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editMode) {
      const scrollableContainer = document.getElementById(
        "scrollable-container"
      );
      if (scrollableContainer) {
        scrollableContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [editMode]);

  useEffect(() => {
    if (cloneMode) {
      const scrollableContainer = document.getElementById(
        "scrollable-container"
      );
      if (scrollableContainer) {
        scrollableContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [cloneMode]);
  const handleEdit = (index, _id) => {
    // console.log("Index", index);
    setEditMode(true);
    // console.log("Edit Id", _id);
    const entryToEdit = index;
    // console.log("Entry to edit", entryToEdit);
    // console.log("Projects", projects);
    const projectId = projects.find(
      (proj) => proj.projectId === entryToEdit.field_proj?._id
    )?.projectId;
    // console.log("Get Projects Id", projectId);
    // console.log("Prefill Data", {
    //   ...entryToEdit,
    //   field_proj: projectId,
    // });
    setTimesheets([
      {
        ...entryToEdit,
        field_proj: projectId,
      },
    ]);

    setEditIndex(index);
  };

  // const handleDelete = async (index, _id, wup_nid) => {
  //   console.log("Index", index, _id);
  //   const confirmDelete = window.confirm(
  //     "Are you sure you want to delete this timesheet?"
  //   );
  //   const updatedEntries = savedEntries.filter((item) => item._id !== _id);
  //   console.log("Updated Entries", updatedEntries);
  //   if (confirmDelete) {
  //     try {
  //       const response = await axios.delete(
  //         `${BASE_URL}/api/timesheet/deleteEntryById/${_id}/${wup_nid}`
  //       );
  //       if (response.status === 200) {
  //         setSavedEntries(updatedEntries);
  //       }
  //     } catch (error) {
  //       console.error("Error deleting timesheet:", error);
  //       // setError("Failed to delete timesheet. Please try again.");
  //     }
  //   }
  // };

  const handleDelete = async () => {
    closeModal();
    const { index, _id, wup_nid } = deleteParams;
    const updatedEntries = savedEntries.filter((item) => item._id !== _id);
    setLoading(true);
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/timesheet/deleteEntryById/${_id}/${wup_nid}`
      );
      if (response.status === 200) {
        setSavedEntries(updatedEntries);
        setLoading(false);
      }
      setModalOpen(false); // Close the modal after successful deletion
    } catch (error) {
      console.error("Error deleting timesheet:", error);
    }
  };

  const handleUpdateSubmit = async (event) => {
    handleSaveForPreview();
    setIsLoading(true);
    event.preventDefault();

    const project = projects.find(
      (proj) => proj.projectId === timesheets?.[0]?.field_proj?._id
    );

    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?.shortForm === timesheets?.[0]?.field_entrytask}
    )

  
    const data = {
      body: timesheets?.[0]?.body,
      field_entrydate: timesheets?.[0]?.field_entrydate,
      field_entrytask: timesheets?.[0]?.field_entrytask,
      field_proj: timesheets?.[0]?.field_proj,
      field_ticket_number: timesheets?.[0]?.field_ticket_number,
      field_time_spent: timesheets?.[0]?.field_time_spent,
      wup_pid: project?.wup_pid,
      wup_tid: taskFiltered?.wup_tid,
      // title: `wup-${localStorage?.getItem("wup_username")}-${Date.now()}`,
    };

    axios
      .put(
        `${BASE_URL}/api/timesheet/updateTimesheetEntryById/${
          timesheets?.[0]?._id
        }/${timesheets?.[0]?.wup_nid}/${
          timesheets?.[0]?.title
        }/${localStorage?.getItem("wup_username")}/${
          timesheets?.[0]?.wup_pid
        }/${taskFiltered?.wup_tid}`,
        data
      )
      .then(async (response) => {
        if (response.status === 200) {
          console.log("Updated successfully:", response.data);
          setIsLoading(false);
          setEditMode(false);
          setTimesheets([
            {
              title: "",
              uid: localStorage.getItem("uid"),
              field_entrydate: new Date().toISOString().split("T")[0],
              field_ticket_number: "",
              field_proj: "",
              field_entrytask: "",
              body: "",
              field_time_spent: "",
            },
          ]);

          await fetchTimesheetsInRange();
          setHasUnsavedEntry(false);
        }
      })
      .catch((error) => {
        console.error("Error updating timesheet:", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      setErrorMessage("Please fix the errors before saving for preview.");
      return;
    }
    setErrorMessage("");
    setSuccessMessage("");
    handleSaveForPreview();
    console.log("Entries", timesheets);
    if (timesheets.length === 0) {
      setErrorMessage("Minimum 1 entry is required");
      return;
    }

    // console.log("Id Data", timesheets?.[0]?.field_entrytask);
    // console.log("Projects Data", projects);
    const projectF = projects.find(
      (proj) => proj.projectId === timesheets?.[0]?.field_proj
    );
    // const taskFiltered = tasks.find(
    //   (task) => task?.shortForm === timesheets?.[0]?.field_entrytask
    // );

    const taskArray = [...tasks.recentlyUsed , ...tasks.commonlyUsed , ...tasks.others];    
    const taskFiltered = taskArray.find( 
      (task) => {        
        return task?.shortForm === timesheets?.[0]?.field_entrytask}
    )
 
    const newFormData = [
      {
        ...timesheets[0],
        wup_uid: localStorage.getItem("wup_username"),
        wup_pid: projectF?.wup_pid,
        wup_tid: taskFiltered?.wup_tid,
        title: `wup-${localStorage?.getItem("wup_uid")}-${Date.now()}`,
      },
    ];
    // return;
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/timesheet/massInsert`,
        newFormData
      );
      if (response.status === 201) {
        setSuccessMessage("Timesheets submitted successfully!");
        setHasUnsavedEntry(false);
        setTimesheets([
          {
            title: "",
            uid: localStorage.getItem("uid"),
            field_entrydate: new Date().toISOString().split("T")[0],
            field_ticket_number: "",
            field_entrytask: "",
            body: "",
            field_time_spent: "",
            field_proj: projectF.projectId,
          },
        ]);
        await fetchTimesheetsInRange();
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTimesheetApproval = (e) => {};

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  if (
    !permissions?.includes(PERMISSIONS.TIMESHEET_PERMISSIONS.SUBMIT_TIMESHEET)
  ) {
    return <PermissionDenied />;
  }

  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full p-4 md:p-6 bg-white rounded-lg">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
         <button
            type="button"
            onClick={() => {
              setLastWeekStartOff(subtractDays(weekStartOff, 7));
              setLastWeekEndOf(subtractDays(weekEndOf, 7));
            }}
            className=" text-white bg-[#213251] rounded-full py-2 px-4 flex items-center "
          >
            <IoMdArrowRoundBack className="mr-2"/>
            <span className="block">Previous Week</span>
            </button>
        
          <h1 className="text-2xl font-semibold mb-4 md:w-[25rem] text-center font-montserrat ">
            {isDateLess(currentDate) ? "Timesheet Entry" : "Timesheet Entry"}{" "}
          </h1>
          {isDateLess() ? (

            <button
              type="button"
              onClick={() => {
                setLastWeekStartOff(addDays(weekStartOff, 7));
                setLastWeekEndOf(addDays(weekEndOf, 7));
              }}
              className="bg-[#213251] rounded-full text-white py-2 px-4 flex items-center"
            >
              <span className="block">Next Week</span>
              <IoMdArrowRoundForward className="ml-2"/>    
              </button>
        
          ) : (
            <div></div>
          )}
        </div>
        <p className="font-medium self-center w-full text-center pb-3 text-blue-500">
          From {convertDateToWords(weekStartOff.toString())} to{" "}
          {convertDateToWords(weekEndOf.toString())}
        </p>
        {successMessage && (
          <p className="text-green-600 mb-2">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-600 mb-2">{errorMessage}</p>}
        <form
          onSubmit={editMode ? handleUpdateSubmit : handleSubmit}
          className="space-y-4"
        >
          {timesheets.map((timesheet, index) => (
            <div
              key={index}
              className="bg-gray-50 p-4 rounded-lg shadow-md mb-4"
            >
              <div className="grid grid-cols-1 md:grid-cols-5 gap-4 items-center">
                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Project Name <span className="text-red-600">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    value={timesheet.field_proj}
                    onChange={(e) => handleProjectChange(index, e.target.value)}
                  >
                    <option value="">Select Project</option>
                    {projects.map((project) => (
                      <option key={project.projectId} value={project.projectId}>
                        {project.projectName}
                      </option>
                    ))}
                  </select>
                  {errors[index]?.field_proj && (
                    <p className="text-red-500">{errors[index].field_proj}</p>
                  )}
                </div>

                  <div>
      <label className="block text-gray-700 font-montserrat">
        Task Name <span className="text-red-600">*</span>
      </label>
      <select
        className="w-full border border-gray-300 rounded mt-1 p-2"
        value={timesheet.field_entrytask}
        onChange={(e) => handleTaskChange(index, e.target.value)}
      >
        <option value="">Select Task</option>

        {/* Recently Used Tasks */}
        <optgroup label="Recently Used">
          {tasks.recentlyUsed.map((task, i) => (
            <option key={`recent-${i}`} value={task.shortForm}>
              {task.title}
            </option>
          ))}
        </optgroup>

        {/* Commonly Used Tasks */}
        <optgroup label="Commonly Used">
          {tasks.commonlyUsed.map((task, i) => (
            <option key={`common-${i}`} value={task.shortForm}>
              {task.title}
            </option>
          ))}
        </optgroup>

        {/* Other Tasks */}
        <optgroup label="Others">
          {tasks.others.map((task, i) => (
            <option key={`other-${i}`} value={task.shortForm}>
              {task.title}
            </option>
          ))}
        </optgroup>
      </select>

      {errors[index]?.field_entrytask && (
        <p className="text-red-500">
          {errors[index].field_entrytask}
        </p>
      )}
    </div>

                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Entry Date <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="date"
                    name="field_entrydate"
                    value={timesheet.field_entrydate}
                    min={weekStartOff}
                    max={weekEndOf}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.field_entrydate && (
                    <p className="text-red-500">
                      {errors[index].field_entrydate}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Ticket Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="field_ticket_number"
                    value={timesheet.field_ticket_number}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.field_ticket_number && (
                    <p className="text-red-500">
                      {errors[index].field_ticket_number}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-700 font-montserrat">
                    Time Spent (hours) <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    name="field_time_spent"
                    value={timesheet.field_time_spent}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full border border-gray-300 rounded mt-1 p-2"
                    required
                  />
                  {errors[index]?.field_time_spent && (
                    <p className="text-red-500">
                      {errors[index].field_time_spent}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <label className="block text-gray-700 font-montserrat">
                  Details <span className="text-red-600">*</span>
                </label>
                <textarea
                  name="body"
                  value={timesheet.body}
                  onChange={(e) => handleInputChange(index, e)}
                  className="w-full border border-gray-300 rounded mt-1 p-2"
                  required
                />
                {errors[index]?.body && (
                  <p className="text-red-500">{errors[index].body}</p>
                )}
              </div>

              {index > 0 && (
                <button
                  type="button"
                  onClick={() => handleRemoveTimesheet(index)}
                  className="bg-red-500 text-white py-1 px-4 mt-2 flex gap-2 items-center rounded"
                >
                  <FaTrash /> Remove
                </button>
              )}
            </div>
          ))}

          <div className="flex justify-between mt-4">
            {/* <button
              type="button"
              onClick={handleSaveForPreview}
              className="bg-green-500 text-white py-2 px-4 rounded"
            >
              Save Timesheet
            </button> */}

            <div className="flex justify-between w-full mt-4">
              <button
                type="submit"
                onClick={editMode ? handleUpdateSubmit : handleSubmit}
                className={`bg-[#077Fc0] text-white py-2 px-4 rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <ProgressLoader /> Submitting...
                  </>
                ) : editMode ? (
                  "Update Timesheet"
                ) : (
                  "Submit Timesheets"
                )}
              </button>
              {trackEmployementStatus() && (
                <button
                  onClick={() => setShowApprovalPopup(true)}
                  className={`bg-[#FF480F] text-white py-2 px-4 rounded ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  Request Timesheet Approval
                </button>
              )}
            </div>
          </div>
        </form>
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={handleDelete}
        />
        {isTableLoading ? (
          "Fetching Records..."
        ) : (
          <SavedEntriesTable
            handleEdit={handleEdit}
            savedEntries={savedEntries}
            handleDelete={openModal}
            handleClone={handleClone}
          />
        )}
      </div>
      {showApprovalPopup && (
        <ShowTimesheetApprovalPopup hidePopup={setShowApprovalPopup} />
      )}
    </div>
  );
};

export default TimesheetForm;
