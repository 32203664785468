import axios, { HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Constants";
import WeeklyHoursTable from "./WeeklyHourTable";
import {
  convertToDDMMYYYY,
  getCurrentWeekNumber,
  getWeekRanges,
} from "../../utensils/dateUtils";

function ResourceUtilization() {
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterProject, setFilterProject] = useState("all");
  const [apiData, setApiData] = useState();
  const currentYear = new Date().getFullYear();
  const weeks = getWeekRanges(currentYear);

  const [selectedWeek, setSelectedWeek] = useState(
    getCurrentWeekNumber() - 1 === 0 ? 1 : getCurrentWeekNumber() - 1
  );

  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    console.log("Filter User", filterProject);
  }, [filterProject]);

  useEffect(() => {
    console.log("Selected Week", selectedWeek);
    const startDate = weeks.find(
      (w) => w.week === parseInt(selectedWeek)
    )?.start;
    const endDate = weeks.find((w) => w.week === parseInt(selectedWeek))?.end;
    setStartDate(startDate);
    setEndDate(endDate);
  }, [selectedWeek]);

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/user/${localStorage.getItem(
          "uid"
        )}/filterProjects/role/Project%20Manager`
      );
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
      }
      setProjects(response.data?.uniqueProjects);
      setUsers(response.data?.uniqueUsers);
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong");
      console.error("Error fetching projects:", error);
    }
  };

  const regenerateNodes = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/refreshNodes/${startDate}/${endDate}`
      );
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
        setApiData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong");
      console.error("Error fetching projects:", error);
    }
  };
  const fetchReports = async () => {
    //timesheet-be.fleetstudio.com/api/user/reports/filter/2024-12-01/2024-12-08
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/reports/filter/${startDate}/${endDate}`
      );
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
        setApiData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong");
      console.error("Error fetching projects:", error);
    }
  };
  return (
    <div>
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Timesheet Logs</h1>
        {/* Filter Section */}
        <div className="bg-white shadow-md p-4 mb-6 rounded">
          <h2 className="text-xl font-semibold mb-2">Filter Timesheets</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {
              <div>
                <label className="block font-medium mb-2">
                  Select Project <sup className="text-red-600">*</sup>
                </label>
                <select
                  value={filterProject}
                  onChange={(e) => setFilterProject(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="all">All Projects</option>{" "}
                  {projects
                    ?.filter(
                      (filterProject) =>
                        filterProject._id !== "66f68fda5cf392010e45f9f0" &&
                        filterProject._id !== "66f68fb55cf392010e45f9db"
                    )
                    ?.map((project) => (
                      <option key={project._id} value={project._id}>
                        {project.projectName}
                      </option>
                    ))}
                </select>
              </div>
            }

            <div>
              <div className="relative w-64">
                <label
                  htmlFor="week-dropdown"
                  className="block mb-2 text-sm font-medium text-gray-600"
                >
                  Select Week
                </label>
                <select
                  id="week-dropdown"
                  value={selectedWeek}
                  onChange={handleWeekChange}
                  className="block w-full px-4 py-2 text-sm border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="" disabled>
                    -- Select a Week --
                  </option>
                  {weeks.map((week) => (
                    <option key={week.week} value={week.week}>
                      Week {week.week}: ({week?.start} - {week?.end})
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-1 md:col-span-4 flex justify-end gap-6">
              <button
                onClick={regenerateNodes}
                className="bg-red-500 text-white px-4 py-2 rounded mt-4"
              >
                Regenerate Nodes
              </button>
              <button
                onClick={fetchReports}
                className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Fetch Timesheets"}
              </button>
            </div>
          </div>
        </div>
        {apiData && (
          <WeeklyHoursTable
            data={apiData}
            filteredProjectId={filterProject}
            startDate={convertToDDMMYYYY(startDate)}
            endDate={convertToDDMMYYYY(endDate)}
          />
        )}
        {/* Timesheet List */}
      </div>
    </div>
  );
}

export default ResourceUtilization;
