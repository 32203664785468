import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../Constants";
import SavedEntriesTable from "./PreviewTable";
import ApprovedTimesheetTableView from "./ApprovalTimesheetTableView";

function AdminRequestApproval() {
  const { requestId } = useParams();
  const [savedEntries, setSavedEntries] = useState([]);

  const handleFinalSubmit = async () => {
    const isAllApproved = savedEntries.every(
      (data) => data?.isApproved === true
    );
    const response = await axios.post(
      `${BASE_URL}/api/approval/sendMailToUser`,
      {
        requestId,
        isAllApproved,
      }
    );
  };

  const fetchApprovalData = () => {
    axios
      .get(`${BASE_URL}/api/approval/getRequestedData/${requestId}`)
      .then((res) => {
        setSavedEntries(res.data);
      });
  };
  useEffect(() => {
    fetchApprovalData();
  }, []);

  const handleApproveTimesheet = (dataset) => {
    const _ids = dataset.map((data) => data?._id);
    axios
      .put(`${BASE_URL}/api/approval/timesheet/update-status`, {
        entryIds: _ids,
        status: true,
      })
      .then((res) => {
        fetchApprovalData();
      });
  };
  const handleRejectTimesheet = (dataset) => {
    const _ids = dataset.map((data) => data?._id);
    axios
      .put(`${BASE_URL}/api/approval/timesheet/update-status`, {
        entryIds: _ids,
        status: false,
      })
      .then((res) => {
        fetchApprovalData();
      });
  };

  console.log("RequestId", requestId);
  return (
    <div>
      <h1 className="font-bold font-montserrat text-2xl">
        {" "}
        Timesheet Approval
      </h1>
      <ApprovedTimesheetTableView
        savedEntries={savedEntries}
        handleApprove={handleApproveTimesheet}
        handleReject={handleRejectTimesheet}
        handleFinalSubmit={handleFinalSubmit}
      />
    </div>
  );
}

export default AdminRequestApproval;
