// src/utils/dateUtils.js
export const getWeekNumber = (date) => {
  const tempDate = new Date(date.getTime());
  // Set to nearest Thursday: current date + 3 - current day number (getDay())
  // Make Sunday's day number 7
  tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
  // January 4 is always in week 1
  const week1 = new Date(tempDate.getFullYear(), 0, 4);
  // Calculate full weeks to nearest Thursday
  return (
    1 +
    Math.round(
      ((tempDate - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
    )
  );
};

export function convertToDDMMYYYY(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}
export function getCurrentWeekNumber() {
  const currentDate = new Date();

  // Set the start of the year (January 1st)
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = currentDate - startOfYear;

  // Calculate the number of days from the start of the year
  const daysSinceStartOfYear = Math.floor(
    diffInMilliseconds / (24 * 60 * 60 * 1000)
  );

  // Get the week number
  // Day of week offset: Adjust to make Monday the first day of the week
  const dayOfWeekOffset =
    startOfYear.getDay() === 0 ? 6 : startOfYear.getDay() - 1;
  const weekNumber = Math.ceil(
    (daysSinceStartOfYear + dayOfWeekOffset + 1) / 7
  );

  return weekNumber;
}

export function getWeekRanges(year) {
  const startOfYear = new Date(Date.UTC(year, 0, 1)); // January 1st (UTC)
  const endOfYear = new Date(Date.UTC(year, 11, 31)); // December 31st (UTC)
  const weeks = [];

  let currentDate = startOfYear;

  // Adjust the starting date to the nearest Monday (ISO week starts on Monday)
  while (currentDate.getUTCDay() !== 1) {
    currentDate.setUTCDate(currentDate.getUTCDate() - 1);
  }

  let weekNumber = 1;
  while (currentDate <= endOfYear) {
    const startOfWeek = new Date(currentDate);
    currentDate.setUTCDate(currentDate.getUTCDate() + 6);
    const endOfWeek = new Date(currentDate);

    // Ensure the end of the week doesn't exceed the year's last date
    if (endOfWeek > endOfYear) {
      endOfWeek.setUTCDate(endOfYear.getUTCDate());
    }

    weeks.push({
      week: weekNumber,
      start: `${startOfWeek.getUTCFullYear()}-${String(
        startOfWeek.getUTCMonth() + 1
      ).padStart(2, '0')}-${String(startOfWeek.getUTCDate()).padStart(2, '0')}`,
      end: `${endOfWeek.getUTCFullYear()}-${String(
        endOfWeek.getUTCMonth() + 1
      ).padStart(2, '0')}-${String(endOfWeek.getUTCDate()).padStart(2, '0')}`,
    });

    weekNumber++;
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return weeks;
}

// Example Usage
console.log(getWeekRanges(2024));

export function convertDateToWords(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Helper function to add ordinal suffix
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return day + 'th'; // For numbers 11–20
    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }

  const dayWithSuffix = getOrdinalSuffix(day);

  return `${dayWithSuffix} ${month} ${year}`;
}

// Example usage:
const dateInWords = convertDateToWords('2024-10-06');
console.log(dateInWords); // Output: "6th October 2024"
