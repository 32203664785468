import { SlPencil } from "react-icons/sl"; // Import SlPencil
import { FaCheck, FaRegClone, FaTrash } from "react-icons/fa"; // Import FaTrash
import React, { useState, useMemo } from "react";
import { Tooltip } from "../UserDashboard";
import { FcCancel } from "react-icons/fc";
import { IoClose } from "react-icons/io5";
import axios from "axios";

const ApprovedTimesheetTableView = ({
  savedEntries,
  handleApprove,
  handleReject,
  handleFinalSubmit,
}) => {
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Handle selecting/deselecting all entries
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEntries([]); // Deselect all
    } else {
      const allEntryIds = savedEntries.map((entry) => entry._id);
      setSelectedEntries(allEntryIds); // Select all
    }
    setSelectAll(!selectAll);
  };

  // Handle selecting/deselecting a single entry
  const handleSelectEntry = (entryId) => {
    if (selectedEntries.includes(entryId)) {
      setSelectedEntries(selectedEntries.filter((id) => id !== entryId));
    } else {
      setSelectedEntries([...selectedEntries, entryId]);
    }
  };

  // Handle bulk approval
  const handleBulkApprove = () => {
    const selected = savedEntries.filter((entry) =>
      selectedEntries.includes(entry._id)
    );
    console.log("Approval Request Array", selected);
    handleApprove(selected); // Call bulk approval
    setSelectedEntries([]); // Clear selection after action
    setSelectAll(false);
  };

  // Handle bulk rejection
  const handleBulkReject = () => {
    console.log("Data");
    const selected = savedEntries.filter((entry) =>
      selectedEntries.includes(entry._id)
    );
    handleReject(selected); // Call bulk rejection
    setSelectedEntries([]); // Clear selection after action
    setSelectAll(false);
    console.log("Reject Request Array", selected);
  };

  // Memoize grouping entries by date
  const groupedEntries = useMemo(() => {
    return savedEntries?.reduce((acc, entry) => {
      const date = entry.field_entrydate;
      const hours = parseFloat(entry.field_time_spent) || 0;

      if (!acc[date]) {
        acc[date] = { totalHours: 0, entries: [] };
      }

      acc[date].totalHours += hours;
      acc[date].entries.push(entry);

      return acc;
    }, {});
  }, [savedEntries]);

  // Convert the grouped object into an array and sort by date
  const sortedGroupedEntries = useMemo(() => {
    return Object.keys(groupedEntries)
      .sort()
      .map((date) => ({
        date,
        totalHours: groupedEntries?.[date].totalHours,
        entries: groupedEntries?.[date].entries,
      }));
  }, [groupedEntries]);

  // Calculate the overall total hours
  const overallTotalHours = useMemo(() => {
    return sortedGroupedEntries.reduce(
      (sum, group) => sum + group.totalHours,
      0
    );
  }, [sortedGroupedEntries]);

  return (
    <div>
      <div className="border p-2 flex justify-between mt-6 bg-[#213251] px-10 rounded-tl-lg rounded-tr-lg text-white">
        <h2 className="text-base font-semibold font-montserrat">
          Timesheet Entries
        </h2>
        <h2 className="text-base font-semibold font-montserrat">
          Total Hours: {overallTotalHours.toFixed(2)}
        </h2>
      </div>

      <table className="w-full border text-center bg-gray-100">
        <thead className="bg-gray-200 font-montserrat text-sm">
          <tr>
            <th className="border p-2">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th className="border p-2">Project</th>
            <th className="border p-2">Task</th>
            <th className="border p-2">Date</th>
            <th className="border p-2">Ticket Number</th>
            <th className="border p-2">Time Spent</th>
            <th className="border p-2">Description</th>
            <th className="border p-2">Status</th>
          </tr>
        </thead>
        <tbody className="text-sm font-opensans">
          {sortedGroupedEntries.map((group, index) => (
            <React.Fragment key={index}>
              <tr>
                <td
                  colSpan="8"
                  className="border p-2 font-semibold bg-[#077fc0] text-white text-start"
                >
                  <pre>
                    Date: {group.date}, Total Hours:{" "}
                    {group.totalHours.toFixed(2)}
                  </pre>
                </td>
              </tr>
              {group.entries.map((entry, entryIndex) => (
                <tr key={entryIndex} className="hover:bg-gray-50">
                  <td className="border p-2">
                    <input
                      type="checkbox"
                      checked={selectedEntries.includes(entry._id)}
                      onChange={() => handleSelectEntry(entry._id)}
                    />
                  </td>
                  <td className="border p-2">
                    {entry?.field_proj?.projectName}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_entrytask}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_entrydate}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_ticket_number}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_time_spent}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.isHtml ? (
                      <div dangerouslySetInnerHTML={{ __html: entry?.body }} />
                    ) : (
                      entry?.body
                    )}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.isApproved ? (
                      <span className="font-bold text-green-600">Approved</span>
                    ) : (
                      <span className="font-bold text-red-500">Rejected</span>
                    )}
                  </td>
                  {/* <td className="border p-2 capitalize flex justify-center items-center gap-4">
                    <Tooltip text="Approve Entry">
                      <button
                        onClick={() => handleApprove([entry])}
                        className="rounded mr-2"
                      >
                        <FaCheck className="text-xs text-white   bg-green-500  rounded-full w-[2rem] h-[2rem] p-[.5rem]" />
                      </button>
                    </Tooltip>

                    <Tooltip text="Reject Entry">
                      <button
                        onClick={() => handleReject([entry])}
                        className="rounded mr-2"
                      >
                        <IoClose className="text-xs text-white   bg-red-500  rounded-full w-[2rem] h-[2rem] p-[.5rem]" />
                      </button>
                    </Tooltip>
                  </td> */}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {selectedEntries?.length > 0 && (
        <div className="p-2 flex justify-end gap-4">
          <button
            onClick={handleBulkApprove}
            disabled={selectedEntries.length === 0}
            className="bg-green-500 text-white px-4 py-2 rounded disabled:opacity-50"
          >
            Approve Selected
          </button>
          <button
            onClick={handleBulkReject}
            disabled={selectedEntries.length === 0}
            className="bg-red-500 text-white px-4 py-2 rounded disabled:opacity-50"
          >
            Reject Selected
          </button>
        </div>
      )}

      <div className="w-full justify-end flex">
        <button
          className="bg-[#213251] mt-10 text-white rounded-lg px-4 py-2"
          onClick={handleFinalSubmit}
        >
          Final Submit
        </button>
      </div>
    </div>
  );
};

export default ApprovedTimesheetTableView;
