import React, { useEffect, useState } from "react";
import CreateUserForm from "./UserSection/CreateUserComponent";
import UserList from "./UserSection/UserList";
import { IoAdd } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../Constants";
import { PERMISSIONS } from "./PermissionsConstant";
import PermissionDenied from "./Permission/PermissionDenied";

function UsersComponent() {
  const [showForm, setShowForm] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/getUsers`); // Replace with your actual API endpoint
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  return (
    <div className="overfloy-y-scroll h-[100vh] ">
      {permissions.includes(PERMISSIONS.USER_PERMISSIONS.VIEW_USER_LIST) &&
      permissions.includes(
        PERMISSIONS.USER_PERMISSIONS.VIEW_USER_INFORMATION
      ) ? (
        <UserList users={users} refetch={fetchUsers} />
      ) : (
        <PermissionDenied />
      )}
      {permissions?.includes(
        PERMISSIONS.USER_PERMISSIONS.CREATE_USER_INFORMATION
      ) && (
        <button
          onClick={() => setShowForm(true)}
          className="text-white font-semibold w-[3rem] h-[3rem] absolute right-20 bottom-20 bg-red-500 rounded-2xl flex justify-center items-center text-2xl"
        >
          <IoAdd />
        </button>
      )}
      {showForm && (
        <div
          className="fixed w-full h-screen bg-gray-800/80  left-0 right-0 top-0 flex justify-center items-center shadow-inner 
        bottom-0"
        >
          <div className="bg-white md:w-1/2 w-3/4 rounded-xl p-8">
            <CreateUserForm handleClose={setShowForm} refetch={fetchUsers} />
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersComponent;
