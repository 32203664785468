import React from "react";

const ExportCsvDateWise = ({
  jsonData,
  username = "all",
  start_date,
  end_date,
  project = "all",
}) => {
  const handleExportCsv = () => {
    const csvData = [];

    if (!jsonData || typeof jsonData !== "object") {
      console.error("Invalid jsonData:", jsonData);
      return;
    }

    // Process each entry in jsonData
    for (const date in jsonData) {
      if (jsonData.hasOwnProperty(date)) {
        const data = jsonData[date];
        const totalHours = data.totalHours;

        if (!data.entries || !Array.isArray(data.entries)) {
          console.warn("No entries found for date:", date);
          continue;
        }

        // Build CSV data rows
        data.entries.forEach((entry) => {
          const projectName = entry.field_proj
            ? entry.field_proj.projectName
            : "No Project";
          csvData.push({
            Date: date,
            Project: projectName,
            Task: entry.field_entrytask,
            Description: entry.body, // Supports multi-line descriptions
            TimeSpent: entry.field_time_spent,
            User: `${entry.uid.fname} ${entry.uid.lname}`,
            Email: entry.uid.email,
            TotalHours: totalHours,
          });
        });
      }
    }

    if (csvData.length === 0) {
      console.warn("No valid CSV data to export");
      return;
    }

    // Define headers
    const headers = {
      Date: "Date",
      Project: "Project",
      Task: "Task",
      Description: "Description",
      TimeSpent: "Time Spent (hours)",
      User: "User",
      Email: "Email",
      TotalHours: "Total Hours",
    };

    // Call export function
    exportToCsv(
      csvData,
      headers,
      `timesheet_entries_${username}_${start_date}_${end_date}_${project}.csv`
    );
  };

  // Enhanced export function to ensure proper CSV formatting, with multi-line handling
  const exportToCsv = (data, headers, filename) => {
    const csvContent = [
      Object.keys(headers)
        .map((key) => `"${headers[key]}"`)
        .join(","), 
      ...data.map((row) =>
        Object.values(row)
          .map((value) =>
            `"${String(value).replace(/"/g, '""').replace(/\n/g, '\r\n')}"`
          ) 
          .join(",")
      ), 
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <button
        className="py-2 bg-[#ff480f] px-4 text-base text-white rounded w-full md:w-fit"
        onClick={handleExportCsv}
      >
        Export Timesheet CSV
      </button>
    </div>
  );
};

export default ExportCsvDateWise;
