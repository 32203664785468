import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import PermissionDenied from "../Permission/PermissionDenied";
import { PERMISSIONS } from "../PermissionsConstant";

const ProjectManagerUserView = ({ userId, role }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch projects with the specified role for the user
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/user/user/${userId}/projects/role/${role}`
        );
        setProjects(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch projects");
        setLoading(false);
      }
    };

    fetchProjects();
  }, [userId, role]);

  if (loading) return <ProgressLoader />;
  if (error) return <p>{error}</p>;
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return permissions.includes(
    PERMISSIONS.PROJECT_PERMISSIONS.PROJECT_MANAGER_VIEW
  ) ? (
    <div className="max-w-5xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Projects for Role: {role}</h1>

      {projects.length === 0 ? (
        <div className="flex flex-col justify-center items-center">
          <img
            src={require("../../assets/istockphoto-1038232966-612x612.jpg")}
            alt="magnify"
            className="w-[50%]"
            srcset=""
          />
          <p>No projects found for the role {role}.</p>
        </div>
      ) : (
        projects.map((project, index) => (
          <div
            key={project?.project?._id}
            className="bg-white shadow-md rounded-lg mb-6 p-6"
          >
            <div className="mb-4">
              <h2 className="text-2xl font-semibold text-blue-600">
                {project?.project?.projectName}
              </h2>
              <p className="text-gray-600">
                Status:{" "}
                <span
                  className={`${
                    project?.project?.status === "active"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {project?.project?.status}
                </span>
              </p>
              <p className="text-gray-600">
                Start Date:{" "}
                {new Date(project?.project?.startDate).toLocaleDateString()}
              </p>
              <p className="text-gray-600">
                Project Type: {project?.project?.type}
              </p>
            </div>

            <h3 className="text-xl font-semibold mb-2">
              Users in this Project:
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {project?.users?.map((user) => (
                <div
                  key={user._id}
                  className="border border-gray-300 rounded-md p-4 hover:shadow-lg"
                >
                  <p className="font-semibold text-lg">
                    {user.fname} {user.lname}
                  </p>
                  <p className="text-gray-500">{user.email}</p>
                  <p className="text-gray-500">Role: {user.role}</p>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  ) : (
    <PermissionDenied />
  );
};

export default ProjectManagerUserView;
