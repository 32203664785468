import React, { useState, useMemo } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { getCurrentWeekNumber } from "../../utensils/dateUtils";
import { useNavigate } from "react-router-dom";
import {
  IoBook,
  IoBookmarks,
  IoBookmarksOutline,
  IoBookOutline,
  IoCloseCircle,
  IoEye,
  IoGitCommit,
  IoGitCommitOutline,
  IoMoon,
} from "react-icons/io5";

const WeeklyHoursTable = ({ data, filteredProjectId, startDate, endDate }) => {
  const navigate = useNavigate();
  console.log("Inital Data", data);
  const [comments, setComments] = useState(
    data.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.comment[0] || "" }),
      {}
    )
  );

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [comment, showCommentBox] = useState(null);

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;
    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    console.log("FilteredProjectId:", filteredProjectId);
    console.log("Original Data:", sortedData);

    if (filteredProjectId === "all") return sortedData;

    const filtered = sortedData.filter((item) =>
      item.projects.some(
        (project) => String(project.projectId) === String(filteredProjectId)
      )
    );

    console.log("Filtered Data:", filtered);
    return filtered;
  }, [sortedData, filteredProjectId]);
  const handleCommentChange = (id, value) => {
    setComments({ ...comments, [id]: value });
  };

  // const handleCommentSubmit = async (id) => {
  //   const comment = comments[id];
  //   try {
  //     await axios.post(`${BASE_URL}/api/user/report/addComment/${id}`, {
  //       comment,
  //     });
  //   } catch (error) {
  //     console.error("Error updating comment:", error);
  //     alert("Failed to update comment.");
  //   }
  // };

  const handleCommentSubmit = async (id) => {
    const comment = comments[id];
    if (!comment) {
      alert("Comment cannot be empty.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/user/report/addComment/${id}`,
        {
          comment,
        }
      );

      if (response.status === 200) {
        // Assuming the API returns the updated user data with comments
        alert("Comment added successfully!");

        // Update the comments array for the user locally
        setComments((prev) => {
          const updatedComments = [...(prev[id] || []), comment];
          return { ...prev, [id]: updatedComments };
        });
      } else {
        alert("Failed to update comment. Please try again.");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      alert("Failed to update comment.");
    }
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  return (
    <div className="container mx-auto relative">
      <div className="container mx-auto relative">
        <div className="overflow-x-auto max-h-[600px]">
          {" "}
          {/* Limit height for scrolling */}
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-sm">
            <thead className="bg-gray-100 text-gray-600 text-sm font-semibold sticky top-0 z-10">
              <tr>
                {[
                  { key: "name", label: "Name" },
                  { key: "employementStatus", label: "Status" },
                  { key: "allocatedHours", label: "Allocated" },
                  { key: "loggedHours", label: "Logged" },
                  { key: "holiday", label: "Holiday" },
                  { key: "leave", label: "Leave" },
                  { key: "learning", label: "Learning" },
                  { key: "flaggedHours", label: "Flagged" },
                  { key: "allocatedMHoliday", label: "Allocated-Holiday" },
                  { key: "loggedMFlagged", label: "Logged-Flagged" },
                  { key: "difference", label: "Difference" },
                ].map((column) => (
                  <th
                    key={column.key}
                    onClick={() => handleSort(column.key)}
                    className="px-2 py-2 text-left cursor-pointer"
                  >
                    {column.label} {getSortIndicator(column.key)}
                  </th>
                ))}
                <th className="px-2 py-2 text-left">Comment</th>
                <th className="px-2 py-2 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredData.map(
                (item) =>
                  item.isActive && (
                    <tr key={item._id} className="hover:bg-gray-50">
                      <td
                        className="px-2 py-2 text-sm cursor-pointer text-blue-500 underline"
                        onClick={() =>
                          navigate(
                            `/admin/timesheet?startDate=${startDate}&endDate=${endDate}&projectId=${filteredProjectId}&userId=${item.userId}`
                          )
                        }
                      >
                        {item.name}
                      </td>
                      <td className="px-2 py-2 text-sm">
                        {item.employementStatus}
                      </td>
                      <td className="px-2 py-2 text-sm">
                        {item.allocatedHours || 0}
                      </td>
                      <td className="px-2 py-2 text-sm">
                        {item.loggedHours || 0}
                      </td>
                      <td className="px-2 py-2 text-sm">{item.holiday || 0}</td>
                      <td className="px-2 py-2 text-sm">{item.leave || 0}</td>
                      <td className="px-2 py-2 text-sm">
                        {item.learning || 0}
                      </td>
                      <td className="px-2 py-2 text-sm">{item.flaggedHours}</td>
                      <td className="px-2 py-2 text-sm">
                        {item?.allocatedHours - item?.holiday || 0}
                      </td>
                      <td className="px-2 py-2 text-sm">
                        {item.loggedHours - item?.flaggedHours || 0}
                      </td>
                      <td
                        className={`px-2 py-2 text-sm font-semibold ${
                          item.difference > 0
                            ? "text-green-600"
                            : item.difference < 0
                            ? "text-red-600"
                            : "text-yellow-500"
                        }`}
                      >
                        {(
                          (item?.loggedHours || 0) -
                            (item?.flaggedHours || 0) -
                            (item?.allocatedHours || 0) +
                            (item?.holiday || 0) || 0
                        ).toFixed(1)}
                      </td>
                      <td className="px-2 py-2 flex items-center gap-2 ">
                        <input
                          type="text"
                          className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                          value={comments[item._id]}
                          onChange={(e) =>
                            handleCommentChange(item._id, e.target.value)
                          }
                          placeholder="Add comment"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            showCommentBox(item.comment);
                          }}
                        >
                          <IoBookmarksOutline />
                        </button>
                      </td>
                      <td className="px-1 py-2 text-center">
                        <button
                          onClick={() => handleCommentSubmit(item._id)}
                          className="px-2 py-1 text-xs font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {comment && (
        <div className="bg-gray-800/80 shadow-lg rounded  mx-auto my-auto w-full h-screen   fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center">
          <div className="w-[45rem]  min-h-[25rem] max-h-[40rem] rounded-lg bg-white p-6 flex flex-col">
            <div className="flex justify-between w-full">
              <p className="font-bold text-blue-500 text-xl underline font-montserrat">
                Project Manager / Lead Comment
              </p>
              <button onClick={() => showCommentBox(null)}>
                <IoCloseCircle />{" "}
              </button>
            </div>
            {comment?.length > 1 ? (
              <div className="mt-3">
                <ul>
                  {comment?.map((com, index) =>
                    index > 0 ? (
                      <li>
                        <span className="font-semibold mr-2">{index}.</span>
                        {com}
                      </li>
                    ) : (
                      <li></li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <div className="text-center py-20">No comments</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WeeklyHoursTable;
