import React, { useEffect, useState } from "react";
import axios from "axios";
import PermissionDenied from "../Permission/PermissionDenied";
import { PERMISSIONS } from "../PermissionsConstant";
import { IoClose, IoTrash, IoPencil } from "react-icons/io5";
import { BASE_URL } from "../../Constants";
import SortIcon from "../sorticon";

const toCamelCase = (str) => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const ProjectList = ({ projects }) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editProjectData, setEditProjectData] = useState(null);
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState(null);
  const [typeSortOrder, setTypeSortOrder] = useState(null);
  const [statusSortOrder, setStatusSortOrder] = useState(null); 


  const handleSortByName = () => {
    setTypeSortOrder(null); 
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); 
  };

  const handleSortByType = () => {
    setSortOrder(null); 
    setTypeSortOrder(typeSortOrder === "asc" ? "desc" : "asc");
  };
  
  const handleSortByStatus = () => { 
    setSortOrder(null); 
    setTypeSortOrder(null); 
    setStatusSortOrder(statusSortOrder === "asc" ? "desc" : "asc");
  };

  // Filter projects based on search term and type
  const filteredProjects = projects?.filter((project) => {
    const projectName = project?.projectName?.toLowerCase();
    const projectStatus = project?.status?.toLowerCase(); 
  
    const matchesSearchTerm = projectName?.includes(searchTerm.toLowerCase());
    const matchesTypeFilter = typeFilter === "all" || projectStatus === typeFilter;
  
    return matchesSearchTerm && matchesTypeFilter;
  });

   // Sort by project name
   const sortedProjectsByName = [...filteredProjects].sort((a, b) => {
    const nameA = a.projectName.toLowerCase();
    const nameB = b.projectName.toLowerCase();
    return sortOrder === "asc"
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });

  // Sort by project type
  const sortedProjectsByType = [...filteredProjects].sort((a, b) => {
    const typeA = a.type.toLowerCase();
    const typeB = b.type.toLowerCase();
    return typeSortOrder === "asc"
      ? typeA.localeCompare(typeB)
      : typeB.localeCompare(typeA);
  });

      // Sort by project status
      const sortedProjectsByStatus = [...filteredProjects].sort((a, b) => {
        const statusA = a.status.toLowerCase();
        const statusB = b.status.toLowerCase();
        return statusSortOrder === "asc"
          ? statusA.localeCompare(statusB)
          : statusB.localeCompare(statusA);
      });
  

  let sortedProjects = filteredProjects; 

  if (sortOrder) {
    sortedProjects = sortedProjectsByName; 
  } else if (typeSortOrder) {
    sortedProjects = sortedProjectsByType; 
  } else if (statusSortOrder) {
    sortedProjects = sortedProjectsByStatus; 
  }


  const validateForm = () => {
    let errors = {};
    if (!editProjectData.projectName) errors.projectName = "Project name is required.";
    if (!editProjectData.startDate) errors.startDate = "Start date is required.";
    if (editProjectData.status !== 'active' && editProjectData.status !== 'inactive') {
      errors.status = "Status must be either 'active' or 'inactive'.";
    }
    if (!editProjectData.type) errors.type = "Project type is required.";
    if (!editProjectData.wup_pid) errors.wup_pid = "WUP ID is required.";

    setErrors(errors);
    return Object.keys(errors).length === 0; 
  };

  const handleUpdateProject = async () => {
    if (!validateForm()) return;

    const updatePayload = {
      projectName: editProjectData.projectName,
      startDate: editProjectData.startDate,
      endDate: editProjectData.endDate || null,
      status: editProjectData.status,
      type: editProjectData.type,
      wup_pid: editProjectData.wup_pid,
      users: [],
    };

    try {
      setLoading(true);
      await axios.put(
        `${BASE_URL}/api/project/Updateproject/${editProjectData._id}`, 
        updatePayload
      );
      alert("Project details updated successfully.");
      window.location.reload()
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating project:", error.response || error);
      alert("Failed to update project.");
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteProject = async (projectId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this project?");
    if (!confirmDelete) return;

    try {
      setLoading(true);
      await axios.delete(`${BASE_URL}/api/project/Deleteproject/${projectId}`); 
      alert("Project deleted successfully.");
      window.location.reload()
    } catch (error) {
      console.error("Error deleting project:", error.response || error);
      alert("Failed to delete project.");
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (project) => {
    setEditProjectData({ ...project });
    setIsEditing(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setEditProjectData(null);
    setErrors({});
  };

  return permissions?.includes(PERMISSIONS.PROJECT_PERMISSIONS.VIEW_PROJECTS) ? (
    <div className="mx-auto text-[#333333]">
      <h1 className="text-2xl font-semibold mb-6 font-montserrat">Project List</h1>

      <div className="mb-4 flex flex-col md:flex-row gap-4 md:space-x-4">
        <input
          type="text"
          placeholder="Search by project name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded w-full font-montserrat w-3/4"
        />
        <select
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded w-2/4 md:w-1/4"
        >
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {projects?.length > 0 ? (
          <table className="table-auto w-full border-collapse border border-gray-300 shadow-lg text-sm">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="border p-4">
                  <div className="flex items-center space-x-2">
                    <span>Project Name</span>
                    <SortIcon sortOrder={sortOrder} onClick={handleSortByName} />
                  </div>
                </th>
                <th className="border p-4">
                  <div className="flex items-center space-x-2">
                    <span>Type</span>
                    <SortIcon sortOrder={typeSortOrder} onClick={handleSortByType} />
                  </div>
                </th>
                <th className="border p-4">
                  <div className="flex items-center space-x-2">
                    <span>Status</span>
                    <SortIcon sortOrder={statusSortOrder} onClick={handleSortByStatus} />
                  </div>
                </th>
                <th className="border p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedProjects.map((project, index) => (
                <tr
                  key={project?._id}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } hover:bg-gray-100 cursor-pointer`}
                >
                  <td className="border p-4">{project?.projectName}</td>
                  <td className="border p-4">{toCamelCase(project?.type)}</td>
                  <td className="border p-4">{project?.status}</td>
                  <td className=" p-4 flex space-x-2 justify-center">
                    <IoPencil
                      className="text-blue-600 hover:text-blue-800 cursor-pointer"
                      onClick={() => openEditModal(project)}
                    />
                    <IoTrash
                      className="text-red-600 hover:text-red-800 cursor-pointer"
                      onClick={() => handleDeleteProject(project._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500">No projects available</p>
        )}
      </div>

      {isEditing && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800/80 z-10 text-[#333333]">
          <div className="md:max-w-3xl m-auto p-2 text-[#333333] bg-white rounded-lg p-6 shadow-xl">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold font-montserrat">Edit Project</h2>
              <IoClose className="text-xl cursor-pointer" onClick={closeModal} />
            </div>

            <form>
              <div className="flex gap-4">
                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">Project Name</label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editProjectData.projectName}
                    onChange={(e) =>
                      setEditProjectData({
                        ...editProjectData,
                        projectName: e.target.value,
                      })
                    }
                  />
                  {errors.projectName && (
                    <p className="text-red-500 text-sm">{errors.projectName}</p>
                  )}
                </div>

                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">Start Date</label>
                  <input
                    type="date"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editProjectData?.startDate ? editProjectData.startDate.slice(0, 10) : ''} 
                    onChange={(e) =>
                      setEditProjectData({
                        ...editProjectData,
                        startDate: e.target.value,
                      })
                    }
                  />
                  {errors.startDate && (
                    <p className="text-red-500 text-sm">{errors.startDate}</p>
                  )}
                </div>
              </div>

              <div className="flex gap-4">
                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">Status</label>
                  <select
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editProjectData.status}
                    onChange={(e) =>
                      setEditProjectData({
                        ...editProjectData,
                        status: e.target.value,
                      })
                    }
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                  {errors.status && (
                    <p className="text-red-500 text-sm">{errors.status}</p>
                  )}
                </div>

                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">WUP ID</label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editProjectData.wup_pid}
                    onChange={(e) =>
                      setEditProjectData({
                        ...editProjectData,
                        wup_pid: e.target.value,
                      })
                    }
                  />
                  {errors.wup_pid && (
                    <p className="text-red-500 text-sm">{errors.wup_pid}</p>
                  )}
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                  onClick={handleUpdateProject}
                >
                  {loading ? "Updating..." : "Update Project"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  ) : (
    <PermissionDenied />
  );
};

export default ProjectList;
