import React, { useState, useEffect } from "react";
import { IoFlag, IoFlagOutline } from "react-icons/io5";
import ExportCsvDateWise from "./DataExportComponents/ExportDateWise";

export const ReviewTimesheetTable = ({
  timesheets,
  setCurrentId,
  setShowFlagForm,
  start_date,
  end_date,
  project,
  username,
}) => {
  const [groupByProject, setGroupByProject] = useState(false);
  const roles = JSON.parse(localStorage.getItem("roles")) || [];

  useEffect(() => {
    console.log("Start Date:", start_date);
    console.log("End Date:", end_date);
  }, [start_date, end_date]);

  // Function to group and sum timesheets
  const groupAndSum = () => {
    const groupedData = {};

    timesheets.forEach((entry) => {
      const date = entry?.field_entrydate;
      const project = entry?.field_proj?.projectName;
      const username = `${entry?.uid?.fname} ${entry?.uid?.lname}`;
      const hours = parseFloat(entry?.field_time_spent);

      if (groupByProject) {
        // Group by Project Logic
        const groupKey = project || "UNKNOWN";
        if (!groupedData[groupKey]) {
          groupedData[groupKey] = { totalHours: 0, userHours: {}, entries: [] };
        }

        // Aggregate total hours
        groupedData[groupKey].totalHours += hours;

        // Aggregate user-specific hours
        if (!groupedData[groupKey].userHours[username]) {
          groupedData[groupKey].userHours[username] = 0;
        }
        groupedData[groupKey].userHours[username] += hours;

        groupedData[groupKey].entries.push(entry);
      } else {
        // Group by Date Logic
        const groupKey = `${date}_${username}`; // Separate group for each user by date
        if (!groupedData[groupKey]) {
          groupedData[groupKey] = {
            date,
            username,
            totalHours: 0,
            entries: [],
          };
        }

        // Aggregate hours
        groupedData[groupKey].totalHours += hours;
        groupedData[groupKey].entries.push(entry);
      }
    });

    return groupedData;
  };

  const groupedData = groupAndSum();

  // Function to determine export permission
  const canExportData = () => {
    return roles.includes("USER") || roles.includes("ADMIN");
  };

  // For "Group by Date": Alphabetize the grouped data based on username
  const groupedEntries = groupByProject
    ? Object.entries(groupedData) // No changes for Group by Project
    : Object.entries(groupedData).sort((a, b) =>
        a[1].username.localeCompare(b[1].username)
      );

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col md:flex-row justify-between">
        <button
          onClick={() => setGroupByProject(!groupByProject)}
          className="mt-4 md:mt-0 mb-4 px-4 py-2 bg-[#077FC0] text-white rounded order-2 md:order-none"
        >
          {groupByProject ? "Group by Date" : "Group by Project"}
        </button>

        {canExportData() && (
          <ExportCsvDateWise
            jsonData={groupedData}
            start_date={start_date}
            end_date={end_date}
            project={project}
            username={username}
          />
        )}
      </div>
      <table className="min-w-full bg-white border border-gray-300 shadow-lg rounded-lg text-sm ">
        <thead className="bg-gray-200 font-montserrat">
          <tr>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              {groupByProject ? "Project" : "Entry Date"}
            </th>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              Username
            </th>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              Total Time Spent (hrs)
            </th>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              Details
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-opensans">
          {groupedEntries.map(([key, value], index, entries) => {
            const username = value.username;
            const isLastEntryOfUser =
              index === entries.length - 1 ||
              entries[index + 1][1].username !== username;
            const isFirstEntryOfUser =
              index === 0 || entries[index - 1][1].username !== username;

            // Count the number of entries for the current username
            const userEntriesCount = entries.filter(
              ([, entry]) => entry.username === username
            ).length;

            return (
              <React.Fragment key={key}>
                <tr>
                  <td className="border px-4 py-2">
                    {groupByProject
                      ? key
                      : new Date(value.date).toLocaleDateString()}
                  </td>

                  {/* Username column */}
                  {!groupByProject && isFirstEntryOfUser && (
                    <td
                      className="border px-4 py-2 text-center align-middle"
                      rowSpan={userEntriesCount}
                    >
                      {username}
                    </td>
                  )}
                  {groupByProject && (
                    <td className="border px-4 py-2">
                      {Object.keys(value.userHours).map((user, index) => (
                        <div key={index}>{user}</div>
                      ))}
                    </td>
                  )}
  
                  <td className="border px-4 py-2">{value.totalHours.toFixed(2)}</td>
                  <td className="px-4 py-2 flex flex-col justify-center border">
                    {value.entries.map((entry, index) => (
                      <div key={entry?._id} className="flex items-center capitalize">
                        <tr className="flex justify-evenly border-gray-300 py-2 w-full">
                          <td className="px-4 text-left font-semibold text-gray-800 w-[15rem]">
                            {entry?.field_proj?.projectName || "UNKNOWN"}
                          </td>
                          <td className="px-4 text-left text-gray-600 w-[10rem]">
                            {entry?.field_entrytask || "No Task"}
                          </td>
                          <td className="px-4 text-left text-gray-500 w-[20rem] text-sm">
                            {entry?.body
                              ? `${entry.body} (${entry?.field_time_spent} hrs)`
                              : "No Description"}
                          </td>
                        </tr>
                        <div
                          onClick={() => {
                            setCurrentId(entry?._id);
                            setShowFlagForm(true);
                          }}
                          className={`cursor-pointer mx-6 p-2 flex justify-center items-center rounded-full shadow-lg ${
                            entry?.isFlagged ? "text-red-500" : "text-gray-500"
                          }`}
                        >
                          {entry?.isFlagged ? <IoFlag /> : <IoFlagOutline />}
                        </div>
                      </div>
                    ))}
                  </td>
                </tr>

                {/* Empty row after each user's data */}
                {!groupByProject && isLastEntryOfUser && (
                  <tr>
                    <td colSpan="4" className="bg-gray-100 h-[30px]"></td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}

          {/* Add a total row */}
          <tr>
            <td className="border px-4 py-2 font-bold">Total</td>
            <td></td>
            <td className="border px-4 py-2 font-bold">
              {Object.values(groupedData)
                .reduce((sum, entry) => sum + entry.totalHours, 0)
                ?.toFixed(2)}{" "}
              Hours
            </td>
            <td className="border px-4 py-2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
