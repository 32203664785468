import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Create Context
const AuthContext = createContext();

// Create Provider Component
export const AuthProvider = ({ children }) => {
  const [isProjectManager, setIsProjectManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOtherUser, setIsOtherUser] = useState(false);
  const [user, setUser] = useState(null); // { role: 'admin' } or { role: 'user' }
  const login = (userData) => {
    setUser(userData); // userData should contain { role: 'admin' } or { role: 'user' }
  };

  const logout = () => {
    setUser(null);
    localStorage.clear()
    console.log("Logout CLicked")
    window.location.href="/login"
  };

  return (
    <AuthContext.Provider value={{ user, login, logout,isAdmin,isProjectManager,isOtherUser,setIsAdmin,setIsProjectManager,setIsOtherUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook to use Auth Context
export const useAuth = () => useContext(AuthContext);
