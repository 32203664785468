import axios, { HttpStatusCode } from 'axios';
import { BASE_URL } from '../Constants';

// Base configuration for all API requests
const apiClient = axios.create({
  baseURL: BASE_URL, // Change to your base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor for adding authorization token if required
apiClient.interceptors.request.use(
  (config) => {
    // You can add token or any global request config here if needed
    const token = localStorage.getItem('token'); // Example: getting token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Example: Fetching data from API
export const fetchUserData = async (userId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/user/getUserById/${userId}`
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const getConversations = async (
  id,
  setIsLoading,
  setSelectedTimesheet
) => {
  setIsLoading(true); // Start loading state

  try {
    const response = await axios.get(
      `${BASE_URL}/api/timesheet/getAllConversations/${id}`
    );

    if (response.status === HttpStatusCode.Ok) {
      // Check if status is OK
      console.log('Response:', response?.data?.data);
      setSelectedTimesheet(response?.data?.data); // Set the response data
    }
  } catch (error) {
    console.error('Error:', error); // Log the error
    alert('Something went wrong'); // Alert the user
  } finally {
    setIsLoading(false); // Stop loading state
  }
};

export const loginUser = async (email, password) => {
  if (!email || !password) {
    throw new Error('Please enter both email and password.');
  }

  try {
    const response = await axios.post(`${BASE_URL}/api/user/login`, {
      email,
      password,
    });

    if (response.status === 200) {
      const {
        firstName,
        token,
        roles,
        _id,
        appRoles,
        permissions,
        wup_username,
        wup_uid,
        employementStatus,
      } = response.data;
      localStorage.setItem('firstName', firstName);
      localStorage.setItem('token', token);
      localStorage.setItem('wup_username', wup_username);
      localStorage.setItem('wup_uid', wup_uid);
      localStorage.setItem('uid', _id);
      localStorage.setItem('employmentStatus', employementStatus);
      localStorage.setItem('roles', JSON.stringify(roles)); // Store as JSON
      localStorage.setItem('permissions', JSON.stringify(permissions)); // Store as JSON
      localStorage.setItem('appRoles', JSON.stringify(appRoles)); // Store as JSON
      localStorage.setItem('loginTimeStamp', Date.now());

      return response.data; // Return the response data
    }
  } catch (error) {
    console.error('Login error:', error);
    throw new Error('Invalid email or password.');
  }
};
export const deleteTimesheetEntry = async (_id, wup_nid) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/timesheet/deleteEntryById/${_id}/${wup_nid}`
    );
    return response;
  } catch (error) {
    console.error('Error deleting timesheet entry:', error);
    throw error; // Propagate the error back to the component
  }
};

export const updateTimesheetEntry = async (
  timesheetId,
  wupNid,
  title,
  username,
  wupPid,
  wupTid,
  data,
  showEditor
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/timesheet/updateTimesheetEntryById/${timesheetId}/${wupNid}/${title}/${username}/${wupPid}/${wupTid}?editor=${showEditor}`,
      data
    );
    return response;
  } catch (error) {
    console.error('Error updating timesheet:', error);
    throw error;
  }
};

export const fetchProjects = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/project/projects`);
    return response.data; // Return the fetched projects
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error; // Propagate the error for the caller to handle
  }
};

export const fetchTasks = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/user/getUserTasks/${localStorage.getItem("uid")}`);
    return response.data; // Return the fetched tasks
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error; // Propagate the error for the caller to handle
  }
};
export const sendPasswordResetLink = async (email) => {
  const RESET_URL = 'https://works.fleetstudio.com:8443';
  if (!email) {
    throw new Error('Email address is required.');
  }

  try {
    const response = await axios.get(
      `${RESET_URL}/auth/forgot-password?email=${email}`
    );
    return response;
  } catch (error) {
    console.error('Error sending password reset link:', error);
    throw error;
  }
};
export const fetchTimesheetsInRange = async (
  userId,
  weekStartOff,
  weekEndOf
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/timesheet/getTimeSheets?userId=${userId}&startDate=${weekStartOff}&endDate=${weekEndOf}`
    );
    return response.data; // Return the fetched timesheets
  } catch (error) {
    console.error('Error fetching timesheets:', error);
    throw error;
  }
};

export const fetchGroupedApprovalRequests = async (userId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/approval/getRequestStatus/${userId}`
    );
    if (response.data.success) {
      console.log('Grouped Requests:', response.data.groupedRequests);
      return response.data.groupedRequests; // Return the grouped requests data
    } else {
      console.error('Error fetching grouped requests:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error making API call:', error);
    return null;
  }
};
