import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { BASE_URL } from "../../Constants";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "../Permission/PermissionDenied";
import TimesheetTable from "./TimesheetTable";
import UpdateTimesheetModal from "./UpdateTimesheetModal";
import { ReviewTimesheetTable } from "./ReviewTimesheetTable";
import { useLocation } from "react-router-dom";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import ExportCsvDateWise from "./DataExportComponents/ExportDateWise";


// Utility function to get the date 7 days ago in YYYY-MM-DD format
const getPastDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString().split("T")[0];
};

// Utility function to get the current date in YYYY-MM-DD format
const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const TimesheetReview = ({
  start_date,
  end_date,
  project,
  username,
}) => {
  const [timesheets, setTimesheets] = useState([]);
  const [filterProject, setFilterProject] = useState("");
  const [filterTask, setFilterTask] = useState("");
  const [filterUser, setFilterUser] = useState(localStorage.getItem("uid"));
  const [startDate, setStartDate] = useState(start_date || getPastDate(7)); // Default to 7 days ago if undefined
  const [endDate, setEndDate] = useState(end_date || getCurrentDate()); 
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [expandedId, setExpandedId] = useState(null);
  const [flaggedTimesheet, setFlaggedTimesheet] = useState(null);
  const [justification, setJustification] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [isProjectManager, setIsProjectManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOtherUser, setIsOtherUser] = useState(false);
  const location = useLocation(); // Access the current location
  const queryParams = new URLSearchParams(location.search);
  const [groupByProject, setGroupByProject] = useState(false);
  const roles = JSON.parse(localStorage.getItem("roles")) || [];
  
  useEffect(() => {
    // Log to verify initial state values

    // Update state with query parameters, if available
    const queryStartDate = queryParams.get("startDate");
    const queryEndDate = queryParams.get("endDate");

    if (queryStartDate && queryEndDate) {
      setStartDate(convertDateFormat(queryStartDate));  // Convert date format if needed
      setEndDate(convertDateFormat(queryEndDate));
    }
  }, [queryParams]);

  // Function to group and sum timesheets
  const groupAndSum = () => {
    const groupedData = {};

    timesheets.forEach((entry) => {
      const date = entry?.field_entrydate;
      const project = entry?.field_proj?.projectName;
      const hours = parseFloat(entry?.field_time_spent);

      if (groupByProject) {
        // Group by project
        if (!groupedData[project]) {
          groupedData[project] = { totalHours: 0, entries: [] };
        }
        groupedData[project].totalHours += hours;
        groupedData[project]?.entries.push(entry);
      } else {
        // Group by date
        if (!groupedData[date]) {
          groupedData[date] = { totalHours: 0, entries: [] };
        }
        groupedData[date].totalHours += hours;
        groupedData[date].entries.push(entry);
      }
    });

    return groupedData;
  };

  const groupedData = groupAndSum();

  const canExportData = () => {
    return roles.includes("USER") || roles.includes("ADMIN"); 
  };

  function convertDateFormat(dateStr) {
    // Split the input date string by the '/' character
    const [day, month, year] = dateStr.split("/");

    // Return the date in 'yyyy-mm-dd' format
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const appRoles = localStorage.getItem("appRoles");
    if (appRoles?.includes("Project Manager")) {
      setIsProjectManager(true);
    }
    if (appRoles?.includes("Admin")) {
      setIsAdmin(true);
    } else if (
      appRoles?.includes("Developer") ||
      appRoles?.includes("HR") ||
      appRoles?.includes("QA") ||
      appRoles?.includes("TA") ||
      appRoles?.includes("DevOps") ||
      appRoles?.includes("Operations") ||
      appRoles?.includes("Accounts")
    ) {
      setIsOtherUser(true);
    }
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/user/${localStorage.getItem(
          "uid"
        )}/filterProjects/role/Project%20Manager`
      );
      setUsers(response.data?.uniqueUsers);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchProjects2 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/getProjectByUserId/${localStorage.getItem("uid")}`
      );
      const data = response.data?.projects;
      setProjects(data);
    } catch (error) {
      console.log("Error fetching projects:", error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/taskrouter/tasks`);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchTasks();
    fetchProjects2();
    fetchCustomFilter();
  }, []);

  const fetchCustomFilter = async () => {
    const startDate = queryParams.get("startDate"); // Get the 'startDate' param
    const endDate = queryParams.get("endDate"); // Get the 'endDate' param
    const projectId = queryParams.get("projectId"); // Get the 'projectId' param
    const userId = queryParams.get("userId");

    if (startDate && endDate && projectId && userId) {
      setIsLoading(true);

      const params = {
        userId: userId || undefined,
        projectId: projectId || undefined,
        task: filterTask || undefined,
        startDate: convertDateFormat(startDate) || undefined,
        endDate: convertDateFormat(endDate) || undefined,
      };

      console.log("Filter Params", params);
      Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key]
      );

      setIsLoading(true);

      try {
        const response = await axios.get(
          `${BASE_URL}/api/timesheet/getTimeSheets`,
          { params }
        );
        setTimesheets(response.data);
        setError("");
      } catch (error) {
        console.error("Logged error", error.response.data.error);
        setError(
          error?.response?.data?.error ||
            "Failed to fetch timesheets. Please try again."
        );
        setTimesheets([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchTimesheets = async () => {
    const params = {
      userId: filterUser || undefined,
      projectId: filterProject || undefined,
      task: filterTask || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    };

    console.log("Filter Params", params);
    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );

    setIsLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/api/timesheet/getTimeSheets`,
        { params }
      );
      setTimesheets(response.data);
      setError("");
    } catch (error) {
      console.error("Logged error", error.response.data.error);
      setError(
        error?.response?.data?.error ||
          "Failed to fetch timesheets. Please try again."
      );
      setTimesheets([]);
    } finally {
      setIsLoading(false);
    }
  };

  const [updatedData, setUpdatedData] = useState();

  // const handleEdit = (id) => {
  //   // Logic to handle editing the timesheet
  //   const timesheetToEdit = timesheets.find((ts) => ts._id === id);
  //   setUpdatedData(timesheetToEdit);
  // };

  // const handleUpdateTimesheetEntry = async (id) => {
  //   const response = await axios.put(
  //     `${BASE_URL}/api/timesheet/updateTimesheetEntryById/${id}`
  //   );
  // };

  const handleDelete = async (id, wup_nid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this timesheet?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(
          `${BASE_URL}/api/timesheet/deleteEntryById/${id}/${wup_nid}`
        );
        // Refresh the timesheets after deletion
        fetchTimesheets();
        setError(""); // Reset any previous errors
      } catch (error) {
        console.error("Error deleting timesheet:", error);
        setError("Failed to delete timesheet. Please try again.");
      }
    }
  };

  const handleJustify = async () => {
    if (!justification) {
      setError("Justification is required.");
      return;
    }

    const lastFlagCommentId =
      flaggedTimesheet?.flagComment?.length > 0
        ? flaggedTimesheet.flagComment[flaggedTimesheet.flagComment.length - 1]
            ._id
        : null;

    const url = `${BASE_URL}/api/timesheet/justify/${lastFlagCommentId}`;
    try {
      await axios.post(url, { comment: justification });
      setJustification("");
      setFlaggedTimesheet(null);
      setDrawerOpen(false);
      fetchTimesheets(); // Refresh the timesheets list
      setError("");
    } catch (error) {
      console.error("Error justifying timesheet:", error);
      setError("Failed to justify timesheet. Please try again.");
    }
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return permissions?.includes(
    PERMISSIONS.DATA_FILTERING_REPORTING_PERMISSIONS.VIEW_DATA_FILTERS
  ) ? (
    isLoading ? (
      <ProgressLoader />
    ) : (
      <div className="relative">
        <div className="container mx-auto p-4">
          <h1 className="text-3xl font-bold mb-4">Timesheet Logs</h1>
          {/* Filter Section */}
          <div className="bg-white shadow-md p-4 mb-6 rounded">
            <h2 className="text-xl font-semibold mb-2">Filter Timesheets</h2>
            {error && <p className="text-red-500">{error}</p>}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {isProjectManager && (
                <div>
                  <label className="block font-medium mb-2">
                    Select User <sup className="text-red-600">*</sup>
                  </label>
                  <select
                    value={filterUser}
                    onChange={(e) => setFilterUser(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.fname} {user.lname}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div>
                <label className="block font-medium mb-2">
                  Select Project <sup className="text-red-600">*</sup>
                </label>
                <select
                  value={filterProject}
                  onChange={(e) => setFilterProject(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Project</option>
                  {projects.map((project) => (
                    <option key={project._id} value={project.projectId}>
                      {project.projectName}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block font-medium mb-2">
                  Select Task <sup className="text-red-600">*</sup>
                </label>
                <select
                  value={filterTask}
                  onChange={(e) => setFilterTask(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Task</option>
                  {tasks.map((task) => (
                    <option key={task._id} value={task.shortForm}>
                      {task.title}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block font-medium mb-2">
                  Start Date <sup className="text-red-600">*</sup>
                </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div>
                <label className="block font-medium mb-2">
                  End Date <sup className="text-red-600">*</sup>
                </label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="col-span-1 md:col-span-4 flex justify-end">
                <button
                  onClick={fetchTimesheets}
                  className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Fetch Timesheets"}
                </button>
              </div>
               {/* Export Button below Fetch Timesheets */}
            {canExportData() && (
              <div className="col-span-1 md:col-span-4 flex justify-end mt-4">
              
                <ExportCsvDateWise
                  jsonData={groupedData}
                  start_date={startDate}
                  end_date={endDate}
                  project={project}
                  username={username}
                />
              </div>
            )}
            </div>
          </div>
          {/* Timesheet List */}
          <TimesheetTable
            // filteredData={updatedData}
            handleDelete={handleDelete}
            // handleEdit={handleEdit}
            timesheets={timesheets}
            setFlaggedTimesheet={setFlaggedTimesheet}
            setDrawerOpen={setDrawerOpen}
            setExpandedId={setExpandedId}
            expandedId={expandedId}
          />
          {/* <ReviewTimesheetTable timesheets={timesheets} /> */}
        </div>

        {/* Drawer for Flagged Comments */}
        {drawerOpen && flaggedTimesheet && (
          <div className="fixed inset-0 flex z-20 right-0 jus">
            <div
              className="fixed inset-0 bg-black bg-opacity-50 right-0"
              onClick={() => setDrawerOpen(false)}
            ></div>
            <div className="bg-white md:w-[25rem] p-6 shadow-lg overflow-y-auto z-40 right-0 fixed h-full">
              <h2 className="text-xl font-semibold mb-2">Conversations</h2>
              <button
                className="absolute top-6 right-6 text-gray-500"
                onClick={() => setDrawerOpen(false)}
              >
                <IoClose />
              </button>
              {flaggedTimesheet.flagComment.length > 0 ? (
                <ul className="list-disc pl-5 border rounded-sm">
                  {flaggedTimesheet.flagComment.map((comment) => (
                    <li key={comment._id} className="text-gray-700 mb-4">
                      <p className="text-red-400">
                        <strong>Query:</strong> {comment.query}
                      </p>
                      <p>
                        <strong>Response:</strong> {comment.response}
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No flagged comments available.</p>
              )}
              {!flaggedTimesheet.flagComment[
                flaggedTimesheet.flagComment.length - 1
              ]?.response && (
                <div className="absolute bottom-10">
                  <p>
                    <strong>Timesheet:</strong>{" "}
                    {flaggedTimesheet.field_entrytask}
                  </p>
                  <textarea
                    value={justification}
                    onChange={(e) => setJustification(e.target.value)}
                    rows="4"
                    className="w-full p-2 border rounded mb-4"
                    placeholder="Enter your justification here..."
                  />
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <button
                    onClick={handleJustify}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Submit Justification
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  ) : (
    <PermissionDenied />
  );
};

export default TimesheetReview;
