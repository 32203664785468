import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import UserDashboard from "../../components/UserDashboard";
import UserTimesheetPage from "../../components/UserTimesheetPage";
import TimesheetReview from "../../components/TimesheetSection/TimesheetReview";
import UserNotification from "../../components/UserSection/UserNotification";
import UserSettings from "../../components/UserSection/UserSettings";
import UserReport from "../../components/UserSection/UserReport";
import UserHome from "../UserHome/UserHome";
import PmUserView from "../PmUserView/PmUserView";
import ProjectManagerUserView from "../PmUserView/PmUserView";
import ProjectManagerFlag from "../PMFlaggedEntries/ProjectManagerFlag";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import ResourceUtilization from "../Utilisation/ResourceUtilisation";

const UserRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/user/user-timesheet" replace />} />

      <Route path="/user" element={<UserDashboard />}>
        <Route index element={<Navigate to="user-timesheet" replace />} />
        <Route path="home" element={<UserHome />} />

        <Route index path="user-timesheet" element={<UserTimesheetPage />} />
        <Route
          path="project-manager-user-view"
          element={
            <ProjectManagerUserView
              userId={localStorage.getItem("uid")}
              role="Project Manager"
            />
          }
        />
        <Route path="user-timesheet/review" element={<TimesheetReview />} />
        <Route path="user/utilisation" element={<ResourceUtilization />} />
        <Route
          path="project-manager-flags"
          element={
            <ProjectManagerFlag
              userId={localStorage.getItem("uid")}
              role="Project Manager"
            />
          }
        />
        <Route path="notification" element={<UserNotification />} />
        <Route path="settings" element={<UserSettings />} />
        <Route path="reports" element={<UserReport />} />
      </Route>
      <Route path="*" element={<ProgressLoader />} />
    </Routes>
  );
};

export default UserRouter;
