import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "../../components/AdminDashboard";
import UsersComponent from "../../components/UsersComponent";
import AdminSettings from "../../components/AdminSettings";
import ProjectPage from "../../components/ProjectPage.jsx";
import FlaggedTimesheetComponent from "../../components/FlaggedSheet/FlaggedSheet";
import TaskPage from "../../components/TaskPage";
import AdminReport from "../../components/AdminReport/AdminReport";
import TimesheetPage from "../TimesheetPage";
import AdminHome from "../AdminHome/AdminHome.jsx";
import PermissionManagement from "../Permission/PermissionComponent.jsx";
import UserTimesheetPage from "../UserTimesheetPage.jsx";
import AdminTimesheetForm from "../TimesheetSection/AdminTimesheetForm.jsx";
import ProgressLoader from "../ProgressLoader/ProgressLoader.jsx";
import AdminRequestApproval from "../TimesheetSection/AdminRequestApproval.jsx";
import SwaggerAccess from "../SwaggerView/SwaggerAccess.jsx";
import ResourceUtilization from "../Utilisation/ResourceUtilisation.jsx";

const AdminRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/admin/home" replace />} />

      <Route path="/admin" element={<AdminDashboard />}>
        <Route index element={<Navigate to="home" replace />} />

        <Route path="home" element={<AdminHome />} />
        <Route path="users" element={<UsersComponent />} />
        <Route path="utilization" element={<ResourceUtilization />} />
        <Route path="project" element={<ProjectPage />} />
        <Route path="settings" element={<AdminSettings />} />
        <Route path="projects" element={<ProjectPage />} />
        <Route path="permission" element={<PermissionManagement />} />
        <Route path="api-docs" element={<SwaggerAccess />} />
        <Route path="timesheet" element={<TimesheetPage />} />
        <Route path="flaggedEntries" element={<FlaggedTimesheetComponent />} />
        <Route path="create-timesheet" element={<AdminTimesheetForm />} />
        <Route
          path="approval/request/:requestId"
          element={<AdminRequestApproval />}
        />
        <Route path="task" element={<TaskPage />} />
      </Route>
      <Route path="*" element={<ProgressLoader />} />
    </Routes>
  );
};

export default AdminRouter;
