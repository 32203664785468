import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "./PermissionDenied";

const PermissionManagement = () => {
  const [activeTab, setActiveTab] = useState("permissions");
  const [permissionCategories, setPermissionCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [newPermission, setNewPermission] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/permission/permissions`
        );
        setPermissionCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user/getUsers`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchPermissions();
    fetchUsers();
  }, []);

  const handleUserChange = (event) => {
    const userId = event.target.value;
    const user = users.find((user) => user._id === userId);
    setSelectedUser(user);
    setSelectedPermissions(user ? user.permissions.filter(Boolean) : []);
  };

  const togglePermission = (permission) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(
        selectedPermissions.filter((p) => p !== permission)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permission]);
    }
  };

  const handleAssignPermissions = async () => {
    if (!selectedUser) return;

    try {
      const response = await axios.post(
        `${BASE_URL}/api/permission/user/${selectedUser._id}/permissions`,
        {
          permissions: selectedPermissions,
        }
      );
      console.log("Permission Response", response.data?.user?.permissions);
      localStorage.setItem(
        "permissions",
        JSON.stringify(response.data?.user?.permissions)
      );
      alert("Permissions updated successfully");
    } catch (error) {
      console.error("Error updating permissions:", error);
      alert("Error updating permissions");
    }
  };

  const handleAddPermission = async () => {
    if (!newPermission || !selectedCategory) return;

    try {
      await axios.put(
        `${BASE_URL}/api/permission/permissionss/${selectedCategory}`,
        { permission: newPermission }
      );
      setPermissionCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === selectedCategory
            ? {
                ...category,
                permissions: [...category.permissions, newPermission],
              }
            : category
        )
      );
      setNewPermission("");
      setShowPopup(false);
    } catch (error) {
      console.error("Error adding permission:", error);
      alert("Error adding permission");
    }
  };

  const handleDeletePermission = async (categoryId, permission) => {
    try {
      await axios.delete(
        `${BASE_URL}/api/permission/permissionss/${categoryId}`,

        { data: { permission } }
      );
      setPermissionCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === categoryId
            ? {
                ...category,
                permissions: category.permissions.filter(
                  (p) => p !== permission
                ),
              }
            : category
        )
      );
    } catch (error) {
      console.error("Error deleting permission:", error);
      alert("Error deleting permission");
    }
  };
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return permissions.includes(
    PERMISSIONS.ADMINISTRATIVE_PERMISSIONS.MANAGE_PERMISSIONS
  ) ? (
    <div className="p-6  min-h-screen relative text-[#333333]">
      <h1 className="text-3xl font-semibold font-montserrat mb-6">
        Permission Management
      </h1>

      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <button
          className={`${
            activeTab === "permissions"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } px-4 py-2 rounded shadow`}
          onClick={() => setActiveTab("permissions")}
        >
          Manage Permissions
        </button>
        <button
          className={`${
            activeTab === "assign"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } px-4 py-2 rounded shadow`}
          onClick={() => setActiveTab("assign")}
        >
          Assign Permissions
        </button>
      </div>

      {activeTab === "permissions" && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Manage Permissions</h2>
          <div className="overflow-x-auto">
            {permissionCategories.map((category) => (
              <div key={category._id} className="mb-6">
                <h3 className="text-sm md:text-lg font-semibold mb-2 text-blue-500 break-words">
                  {category.category}
                </h3>
                <table className="min-w-full bg-white shadow rounded-lg">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                        Permission
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.permissions.map((permission, idx) => (
                      <tr key={idx}>
                        <td className="px-6 py-4 whitespace-nowrap capitalize">
                          {permission?.replaceAll("_", " ")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            className="text-red-500 hover:text-red-700"
                            onClick={() =>
                              handleDeletePermission(category._id, permission)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "assign" && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Assign Permissions</h2>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="userSelect">
              Select User
            </label>
            <select
              id="userSelect"
              className="w-full px-4 py-2 border rounded"
              onChange={handleUserChange}
            >
              <option value="">Select a user</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.fname} {user.lname}
                </option>
              ))}
            </select>
          </div>
          {selectedUser && (
            <div className="overflow-x-auto">
              {permissionCategories.map((category) => (
                <div key={category._id} className="mb-6">
                  <h3 className="text-sm md:text-lg font-semibold mb-2 text-blue-500 break-words">
                    {category.category}
                  </h3>
                  <table className="min-w-full bg-white shadow rounded-lg">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                          Permission
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {category.permissions.map((permission, idx) => (
                        <tr key={idx}>
                          <td className="px-6 py-4 whitespace-nowrap capitalize">
                            {permission?.replaceAll("_", " ")}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <input
                              type="checkbox"
                              checked={selectedPermissions.includes(permission)}
                              onChange={() => togglePermission(permission)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          )}
          <div className="mt-6">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleAssignPermissions}
            >
              Assign Selected Permissions
            </button>
          </div>
        </div>
      )}

      {/* Floating Action Button */}
      <button
        className="fixed bottom-6 right-6 bg-blue-500 text-white p-3 rounded-full shadow-lg flex items-center justify-center"
        onClick={() => setShowPopup(true)}
      >
        <span className="text-2xl">+</span>
      </button>

      {/* Add Permission Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h3 className="text-lg font-semibold mb-4">Add New Permission</h3>
            <div className="mb-4">
              <label
                className="block text-gray-700 mb-2"
                htmlFor="categorySelect"
              >
                Select Category
              </label>
              <select
                id="categorySelect"
                className="w-full px-4 py-2 border rounded"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                {permissionCategories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.category}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 mb-2"
                htmlFor="newPermission"
              >
                New Permission
              </label>
              <input
                id="newPermission"
                type="text"
                className="w-full px-4 py-2 border rounded"
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleAddPermission}
              >
                Add
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <PermissionDenied />
  );
};

export default PermissionManagement;
