import React from "react";

function PermissionDenied({
  message = "You have one or more permission missing.Please request admin for permission to access this resource",
}) {
  return (
    <div className="flex justify-center items-center flex-col">
      <img
        src={require("../../assets/access-denied.png")}
        className="w-[40%]"
        alt=""
        srcset=""
      />
      <p className="font-semibold text-[#333333] w-3/5">{message}</p>
    </div>
  );
}

export default PermissionDenied;
