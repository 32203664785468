import React, { useEffect, useState } from "react";
import { Link, Outlet,useLocation  } from "react-router-dom";
import { FaClock, FaHome, FaRegUser, FaTasks, FaUser } from "react-icons/fa";
import { IoAddCircle, IoExit, IoFlag, IoNotifications } from "react-icons/io5";
import axios from "axios";
import { useAuth } from "./AuthContext/AuthContext";
import { BASE_URL } from "../Constants";
import { FcFeedback } from "react-icons/fc";
import { PERMISSIONS } from "./PermissionsConstant";

function UserDashboard() {
  useEffect(() => {
    getNotifications();
  }, []);

  const { user, logout } = useAuth();
  const [count, setCount] = useState([]);
  const [isProjectManager, setIsProjectManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOtherUser, setIsOtherUser] = useState(false);
  const appRoles = localStorage.getItem("appRoles");
  const location = useLocation();
  const [permissions, setPermissions] = useState(localStorage.getItem("permissions"));

  useEffect(() => {
    const userId = localStorage.getItem("uid");

    if (userId) {
      console.log("Fetching permissions for user ID:", userId);
      axios
        .get(`${BASE_URL}/api/permission/user/${userId}/permissions`)
        .then((response) => {
          console.log("Permissions fetched successfully:", response.data);
          localStorage.setItem("permissions", JSON.stringify(response.data.permissions));
          setPermissions(response.data.permissions);
        })
        .catch((error) => {
          console.log("Error fetching permissions:", error);
        });
    } else {
      console.log("No user ID found in local storage.");
    }
  }, [location]); 


  useEffect(() => {
    if (appRoles?.includes("Project Manager")) {
      setIsProjectManager(true);
    }
    if (appRoles?.includes("Admin")) {
      setIsAdmin(true);
    } else if (
      appRoles?.includes("Developer") ||
      appRoles?.includes("HR") ||
      appRoles?.includes("QA") ||
      appRoles?.includes("TA") ||
      appRoles?.includes("DevOps") ||
      appRoles?.includes("Operations") ||
      appRoles?.includes("Accounts")
    ) {
      setIsOtherUser(true);
    }
  }, []);

  const getNotifications = async () => {
    const response = await axios.get(
      `${BASE_URL}/api/notification/notifications/${localStorage.getItem("uid")}/unread`
    );
    setCount(response?.data);
  };

  const getInitials = () => {
    const fname = localStorage?.getItem("firstName");
    return fname?.substring(0, 2)?.toUpperCase();
  };

  return (
    <div className="w-full h-screen overflow-y-hidden scrollbar-hide">
      <div className="flex">
        <div className="w-[6rem] h-[100vh] flex bg-[#213251] shadow-4xl flex-col">
          <p className="self-center text-lg font-extrabold  w-[3rem] h-[3rem] text-white mt-10 capitalize rounded-full flex justify-center items-center bg-white/50 mb-10">
            {getInitials() || "User"}
          </p>
          <ul className="flex flex-col gap-2 text-white justify-center items-center">
            <Tooltip text="Home">
              <Link
                className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                to={"/user/home"}
              >
                <FaHome />
              </Link>
            </Tooltip>
            {permissions?.includes(
              PERMISSIONS.PROJECT_PERMISSIONS.PROJECT_MANAGER_VIEW
            ) && (
              <Tooltip text="User Management">
                <Link
                  className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                  to={"/user/project-manager-user-view"}
                >
                  <FaUser />
                </Link>
              </Tooltip>
            )}

            {permissions?.includes(
              PERMISSIONS.TIMESHEET_PERMISSIONS.SUBMIT_TIMESHEET
            ) && (
              <Tooltip text="Add Timesheet">
                <Link
                  className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                  to={"/user/user-timesheet"}
                >
                  <IoAddCircle />
                </Link>
              </Tooltip>
            )}
            {permissions?.includes(
              PERMISSIONS.DATA_FILTERING_REPORTING_PERMISSIONS.VIEW_DATA_FILTERS
            ) && (
              <Tooltip text="Review Timesheet">
                <Link
                  className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                  to={"/user/user-timesheet/review"}
                >
                  <FaClock />
                </Link>
              </Tooltip>
            )}
            {permissions?.includes(
              PERMISSIONS.PROJECT_PERMISSIONS.PROJECT_MANAGER_VIEW
            ) && (
              <Tooltip text="Flagged Entries">
                <Link
                  className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                  to={"/user/project-manager-flags"}
                >
                  <IoFlag />
                </Link>
              </Tooltip>
            )}
            <Tooltip text="Notifications">
              <Link
                className="relative text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                to={"/user/notification"}
              >
                <IoNotifications />
                <p className="absolute w-[1.25rem] text-xs flex justify-center items-center top-0 right-0 h-[1.25rem] bg-red-500 rounded-full">
                  {count?.length}
                </p>
              </Link>
            </Tooltip>
            <Tooltip text="Feedback">
              <Link
                className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                to="https://docs.google.com/forms/d/1BCJM3m8P0GlHPSEWkS6HV-GmXbRTi0B8eQ-oF2u7pyw/edit"
                target="_"
              >
                <FcFeedback />
              </Link>
            </Tooltip>
            <Tooltip text="Logout">
              <Link
                onClick={() => {
                  logout();
                  localStorage.clear();
                }}
                className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] flex items-center justify-center rounded-md"
                to={"/logout"}
              >
                <IoExit />
              </Link>
            </Tooltip>
          </ul>
        </div>
        <div
          className="w-full h-[90vh] m-12 rounded-lg overflow-y-scroll"
          id="scrollable-container"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

// Tooltip Component
export const Tooltip = ({ text, children }) => (
  <div className="relative group">
    {children}
    <div className="absolute text-xs bottom-full font-medium  left-10 px-3  py-2 text-center text-white bg-black/90 rounded hidden group-hover:block">
      {text}
    </div>
  </div>
);

export default UserDashboard;
